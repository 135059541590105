@charset "UTF-8";
/*!
 * Boosted  v5.2.3 (https://boosted.orange.com/)
 * Copyright 2014-2022 The Boosted Authors
 * Copyright 2014-2022 Orange SA
 * Licensed under MIT (https://github.com/Orange-OpenSource/Orange-Boosted-Bootstrap/blob/main/LICENSE)
 * This a fork of Bootstrap: Initial license below
 * Bootstrap  v5.2.3 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
/* stylelint-disable-next-line function-disallowed-list */
:root {
  --bs-blue: #527edb;
  --bs-indigo: #a885d8;
  --bs-purple: #a885d8;
  --bs-pink: #ffb4e6;
  --bs-red: #cd3c14;
  --bs-orange: #ff7900;
  --bs-yellow: #fc0;
  --bs-green: #32c832;
  --bs-teal: #50be87;
  --bs-cyan: #4bb4e6;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #999;
  --bs-gray-dark: #595959;
  --bs-gray-100: #fafafa;
  --bs-gray-200: #f6f6f6;
  --bs-gray-300: #eee;
  --bs-gray-400: #ddd;
  --bs-gray-500: #ccc;
  --bs-gray-600: #999;
  --bs-gray-700: #666;
  --bs-gray-800: #595959;
  --bs-gray-900: #333;
  --bs-primary: #ff7900;
  --bs-secondary: #000;
  --bs-success: #32c832;
  --bs-info: #527edb;
  --bs-warning: #fc0;
  --bs-danger: #cd3c14;
  --bs-light: #ccc;
  --bs-dark: #000;
  --bs-primary-rgb: 255, 121, 0;
  --bs-secondary-rgb: 0, 0, 0;
  --bs-success-rgb: 50, 200, 50;
  --bs-info-rgb: 82, 126, 219;
  --bs-warning-rgb: 255, 204, 0;
  --bs-danger-rgb: 205, 60, 20;
  --bs-light-rgb: 204, 204, 204;
  --bs-dark-rgb: 0, 0, 0;
  --bs-primary-text-rgb: 241, 110, 0;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 0, 0, 0;
  --bs-body-bg-rgb: 255, 255, 255;
  --o-chevron-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 14'%3e%3cpath d='M9 2 7 0 0 7l7 7 2-2-5-5 5-5z'/%3e%3c/svg%3e");
  --o-close-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='116 116 767 767' fill='%23000'%3e%3cpath d='M817.493 676.165a49.977 49.977 0 0 1 0 70.664l-70.664 70.664a49.977 49.977 0 0 1-70.664 0L499.5 640.828 322.835 817.493a49.977 49.977 0 0 1-70.664 0l-70.664-70.664a49.977 49.977 0 0 1 0-70.664L358.172 499.5 181.507 322.835a49.977 49.977 0 0 1 0-70.664l70.664-70.664a49.977 49.977 0 0 1 70.664 0L499.5 358.172l176.665-176.665a49.977 49.977 0 0 1 70.664 0l70.664 70.664a49.977 49.977 0 0 1 0 70.664L640.828 499.5Z'/%3e%3c/svg%3e");
  --o-check-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 12'%3e%3cpath fill='%23000' d='M13 0 5 8 2 5 0 7l5 5L15 2z'/%3e%3c/svg%3e");
  --o-success-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 125 125'%3e%3cpath fill='%2332c832' d='M62.5 0a62.5 62.5 0 1 0 0 125 62.5 62.5 0 0 0 0-125zm28 29.4c3.3 0 6 2.6 6 5.9a5.9 5.9 0 0 1-1.3 3.7L57.7 86a5.8 5.8 0 0 1-9.1 0L29.8 62.5c-.8-1-1.2-2.3-1.2-3.7a5.9 5.9 0 0 1 1.7-4.1l2.3-2.4a5.8 5.8 0 0 1 4.2-1.7 5.8 5.8 0 0 1 3.8 1.4L52 64.7 86.6 31a5.8 5.8 0 0 1 4-1.6z'/%3e%3c/svg%3e");
  --o-error-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 140 125'%3e%3cpath fill='%23cd3c14' d='M70.3 0c-5.8 0-10.8 3.1-13.5 7.8L2.3 101.3l-.2.2A15.6 15.6 0 0 0 15.6 125H125a15.6 15.6 0 0 0 13.5-23.5L83.8 7.8A15.6 15.6 0 0 0 70.3 0zm19.2 50a6.4 6.4 0 0 1 4.4 1.9 6.4 6.4 0 0 1 0 9L79.4 75.6l15 15a6.4 6.4 0 0 1 0 9.2 6.4 6.4 0 0 1-4.5 1.9 6.4 6.4 0 0 1-4.6-2l-15-15-15 15a6.4 6.4 0 0 1-4.6 2 6.4 6.4 0 0 1-4.6-2 6.4 6.4 0 0 1 0-9l15-15L46.8 61a6.4 6.4 0 1 1 9-9.1l14.6 14.5L84.8 52a6.4 6.4 0 0 1 4.7-1.9z'/%3e%3c/svg%3e");
  --bs-font-sans-serif: HelvNeueOrange/*rtl:insert:Arabic*/, "Helvetica Neue", Helvetica, "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.125;
  --bs-body-color: #000;
  --bs-body-bg: #fff;
  --bs-border-width: 0.125rem;
  --bs-border-style: solid;
  --bs-border-color: #000;
  --bs-border-color-translucent: #ccc;
  --bs-border-radius: ;
  --bs-border-radius-sm: ;
  --bs-border-radius-lg: ;
  --bs-border-radius-xl: ;
  --bs-border-radius-2xl: ;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #000;
  --bs-link-hover-color: #f16e00;
  --bs-focus-visible-inner-color: #fff;
  --bs-focus-visible-outer-color: #000;
  --bs-code-color: #666;
  --bs-highlight-color: #fff;
  --bs-highlight-bg: #000;
}

[class*=bg-black],
[class*=-dark]:not(.border-dark):not(.text-dark):not(.btn-dark),
[class*=bg-secondary] {
  --bs-primary-text-rgb: 255, 121, 0;
  --bs-link-color: #fff;
  --bs-link-hover-color: #ff7900;
  --bs-focus-visible-inner-color: #000;
  --bs-focus-visible-outer-color: #fff;
  --o-caption-color: #fff;
  --bs-code-color: #999;
  --bs-highlight-color: #000;
  --bs-highlight-bg: #fff;
  --o-kbd-color: #fff;
  --o-kbd-bg: #333;
  --o-pre-color: #eee;
}
[class*=bg-]:not([class*=bg-black],
[class*=-dark]:not(.border-dark):not(.text-dark):not(.btn-dark),
[class*=bg-secondary]):not(.bg-transparent) {
  --bs-primary-text-rgb: 241, 110, 0;
  --bs-link-color: #000;
  --bs-link-hover-color: #f16e00;
  --bs-focus-visible-inner-color: #fff;
  --bs-focus-visible-outer-color: #000;
  --o-caption-color: var(--o-caption-color, #000);
  --bs-code-color: #666;
  --bs-highlight-color: #fff;
  --bs-highlight-bg: #000;
  --o-kbd-color: var(--o-kbd-color, #000);
  --o-kbd-bg: var(--o-kbd-bg, #eee);
  --o-pre-color: var(--o-pre-color, #333);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  scroll-padding-top: 3.75rem;
}
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}
@media (min-width: 1024px) {
  :root {
    scroll-padding-top: 7.5rem;
  }
}

body {
  position: relative;
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-synthesis: none;
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  /* rtl:remove */
  letter-spacing: -0.00625rem;
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: subpixel-antialiased;
  text-rendering: optimizespeed;
}

:focus {
  z-index: 5;
  isolation: isolate;
  outline: 3px solid var(--bs-focus-visible-outer-color);
  outline-offset: 2px;
  box-shadow: 0 0 0 2px var(--bs-focus-visible-inner-color);
}

.js-focus-visible :focus:not([data-focus-visible-added]),
.js-focus-visible .focus:not([data-focus-visible-added]) {
  outline: 0 !important;
  box-shadow: none;
}

:focus:not(:focus-visible) {
  outline: 0 !important;
  box-shadow: none;
}

hr {
  margin: 1.25rem 0;
  color: inherit;
  border: 0;
  border-top: 0.125rem solid;
}

[class*=display-], h3, .h3,
h4,
.h4,
.card-title,
h5,
.h5,
h6,
.h6,
.card-subtitle, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 1.25rem;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.125;
  /* rtl:remove */
  letter-spacing: -0.00625rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
}

h1, .h1 {
  font-size: 1.25rem;
  line-height: 1.1;
  /* rtl:remove */
  letter-spacing: -0.025rem;
}

h2, .h2 {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  /* rtl:remove */
  letter-spacing: -0.0125rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

ul {
  list-style-type: square;
}

li::marker {
  color: var(--bs-link-hover-color);
  vertical-align: middle;
}
ol li::marker {
  color: inherit;
}

li li::marker {
  color: #999;
}

li li li::marker {
  color: #ccc;
}

li::before {
  color: var(--bs-link-hover-color);
  vertical-align: text-top;
}
ol li::before {
  color: inherit;
}

li li::before {
  color: #999;
}

li li li::before {
  color: #ccc;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
em,
strong {
  font-weight: 700;
}

small, .small {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.1428571429;
}

mark, .mark {
  padding: 0 0.1875em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: underline;
}
a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

var,
pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
  line-height: 1.25;
  color: var(--o-pre-color, #333);
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

var,
code {
  font-size: 0.875em;
  font-style: normal;
  line-height: 1.1428571429;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > var,
a > code {
  color: inherit;
}

kbd {
  padding: 0.0625rem 0.0625rem;
  font-size: 0.875em;
  color: var(--o-kbd-color, #000);
  background-color: var(--o-kbd-bg, #eee);
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
  caption-side: top;
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 2.125rem;
  font-weight: 700;
  color: var(--o-caption-color, #000);
  text-align: left;
  /* rtl:remove */
  letter-spacing: -0.0625rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
  font-weight: 700;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  /* rtl:remove */
  letter-spacing: inherit;
  box-shadow: none;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.3125rem;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: inherit;
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.display-1 {
  font-size: 1.875rem;
  line-height: 1.0666666667;
  /* rtl:remove */
  letter-spacing: -0.05rem;
}

h1, .h1,
.display-2,
.display-3 {
  font-size: 1.5rem;
  line-height: 1.0833333333;
  /* rtl:remove */
  letter-spacing: -0.0375rem;
}

/* rtl:begin:remove */
.display-2 {
  letter-spacing: -0.03125rem;
}

/* rtl:end:remove */
h2, .h2,
.display-4 {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  /* rtl:remove */
  letter-spacing: -0.0125rem;
}

.lead {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.125;
  /* rtl:remove */
  letter-spacing: -0.00625rem;
}

@media (min-width: 480px) {
  h1, .h1,
.display-1,
.display-2,
.display-3 {
    line-height: 1;
  }
  .display-1 {
    font-size: 3.125rem;
    /* rtl:remove */
    letter-spacing: -0.1rem;
  }
  .display-2 {
    font-size: 2.5rem;
    /* rtl:remove */
    letter-spacing: -0.0625rem;
  }
  h1, .h1,
.display-3 {
    font-size: 2.125rem;
    /* rtl:remove */
    letter-spacing: -0.0625rem;
  }
  h2, .h2,
.display-4 {
    font-size: 1.5rem;
    line-height: 1.0833333333;
    /* rtl:remove */
    letter-spacing: -0.03125rem;
  }
  h3, .h3,
h4,
.h4,
.card-title,
.lead {
    font-size: 1.125rem;
    line-height: 1.1111111111;
    /* rtl:remove */
    letter-spacing: -0.0125rem;
  }
}
@media (min-width: 1024px) {
  .display-1 {
    font-size: 3.75rem;
    /* rtl:remove */
    letter-spacing: -0.125rem;
  }
  .display-2 {
    font-size: 3.125rem;
    /* rtl:remove */
    letter-spacing: -0.1rem;
  }
  .display-3 {
    font-size: 2.5rem;
    /* rtl:remove */
    letter-spacing: -0.0625rem;
  }
  .display-4 {
    font-size: 2.125rem;
    line-height: 1;
    /* rtl:remove */
    letter-spacing: -0.0625rem;
  }
  h2, .h2 {
    font-size: 1.875rem;
    line-height: 1.0666666667;
    /* rtl:remove */
    letter-spacing: -0.05rem;
  }
  h3, .h3 {
    font-size: 1.5rem;
    line-height: 1.0833333333;
    /* rtl:remove */
    letter-spacing: -0.03125rem;
  }
  h4, .h4, .card-title {
    font-size: 1.25rem;
    line-height: 1.1;
    /* rtl:remove */
    letter-spacing: -0.025rem;
  }
  h5, .h5 {
    font-size: 1.125rem;
    line-height: 1.1111111111;
    /* rtl:remove */
    letter-spacing: -0.0125rem;
  }
  h6, .h6, .card-subtitle {
    font-size: 1rem;
    line-height: 1.125;
    /* rtl:remove */
    letter-spacing: -0.00625rem;
  }
  .lead {
    font-size: 1.25rem;
    line-height: 1.5;
    /* rtl:remove */
    letter-spacing: -0.015625rem;
  }
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.3125rem;
}

.link-chevron {
  font-weight: 700;
  text-decoration: none;
}
.link-chevron::after {
  display: inline-block;
  width: calc(0.5rem - 1px);
  height: 0.625rem;
  margin-left: 0.3125rem;
  vertical-align: middle;
  content: "";
  background-image: var(--o-chevron-icon);
  background-repeat: no-repeat;
  transform: rotate(0.5turn) translateY(1px);
}
.link-chevron:hover {
  text-decoration: underline;
}
.link-chevron:hover::after {
  filter: invert(46%) sepia(60%) saturate(2878%) hue-rotate(6deg) brightness(98%) contrast(104%);
}

.initialism {
  font-size: 0.875rem;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1.25rem;
  font-size: 1.25rem;
  line-height: 1.5;
  /* rtl:remove */
  letter-spacing: -0.0015625rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1.25rem;
  margin-bottom: 1.25rem;
  font-size: 0.875rem;
  line-height: 1.1428571429;
  color: #666;
  /* rtl:remove */
  letter-spacing: -0.00625rem;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0;
  background-color: #fff;
  border: 0.125rem solid var(--bs-border-color-translucent);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.625rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875rem;
  color: #333;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm,
.container-xs {
  --bs-gutter-x: 1.25rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.25);
  padding-left: calc(var(--bs-gutter-x) * 0.25);
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm,
.container-xs {
    --bs-gutter-x: 2.5rem;
  }
}

.container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container-xs {
  max-width: calc(100vw - 8px);
}
@media (min-width: 480px) {
  .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container-xs {
    max-width: calc(100vw - 12px);
  }
}
@media (min-width: 768px) {
  .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container-xs {
    max-width: calc(100vw - 24px);
  }
}
@media (min-width: 1024px) {
  .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container-xs {
    max-width: calc(100vw - 64px);
  }
}
@media (min-width: 1280px) {
  .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container-xs {
    max-width: calc(100vw - 80px);
  }
}
@media (min-width: 1440px) {
  .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container-xs {
    max-width: calc(100vw - 120px);
  }
}

.container {
  max-width: 312px;
}

@media (min-width: 480px) {
  .container-sm, .container {
    max-width: 468px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 744px;
  }
}
@media (min-width: 1024px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1280px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1200px;
  }
}
@media (min-width: 1440px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 0.625rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
@media (min-width: 768px) {
  .row {
    --bs-gutter-x: 1.25rem;
  }
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.3125rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.3125rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.625rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.625rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1.25rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1.25rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.875rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.875rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3.75rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3.75rem;
}

@media (min-width: 480px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.3125rem;
  }
  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.3125rem;
  }
  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.625rem;
  }
  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.625rem;
  }
  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1.25rem;
  }
  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1.25rem;
  }
  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.875rem;
  }
  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.875rem;
  }
  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 3.75rem;
  }
  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 3.75rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.3125rem;
  }
  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.3125rem;
  }
  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.625rem;
  }
  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.625rem;
  }
  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1.25rem;
  }
  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1.25rem;
  }
  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.875rem;
  }
  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.875rem;
  }
  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 3.75rem;
  }
  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 3.75rem;
  }
}
@media (min-width: 1024px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.3125rem;
  }
  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.3125rem;
  }
  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.625rem;
  }
  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.625rem;
  }
  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1.25rem;
  }
  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1.25rem;
  }
  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.875rem;
  }
  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.875rem;
  }
  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 3.75rem;
  }
  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 3.75rem;
  }
}
@media (min-width: 1280px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.3125rem;
  }
  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.3125rem;
  }
  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.625rem;
  }
  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.625rem;
  }
  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1.25rem;
  }
  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1.25rem;
  }
  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.875rem;
  }
  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.875rem;
  }
  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 3.75rem;
  }
  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 3.75rem;
  }
}
@media (min-width: 1440px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.3125rem;
  }
  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.3125rem;
  }
  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.625rem;
  }
  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.625rem;
  }
  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 1.25rem;
  }
  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 1.25rem;
  }
  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1.875rem;
  }
  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1.875rem;
  }
  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 3.75rem;
  }
  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 3.75rem;
  }
}
.table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: #fff;
  --bs-table-border-color: var(--bs-border-color-translucent);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.065);
  --bs-table-striped-hover-color: var(--bs-body-color);
  --bs-table-striped-hover-bg: rgba(0, 0, 0, 0.4);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.135);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.065);
  width: 100%;
  margin-bottom: 1.25rem;
  color: var(--bs-table-color);
  vertical-align: top;
}
.table tr {
  border-bottom: calc(var(--bs-border-width) * 0.5) solid var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.875rem 0.625rem calc(0.875rem + 1px);
  line-height: 1.25;
  background-color: var(--bs-table-bg);
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > thead > tr {
  border-bottom-width: calc(var(--bs-border-width) * 0.5 * 2);
}
.table > tbody > th {
  font-weight: 400;
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table.has-checkbox tr > :first-child {
  width: 2.5rem;
  max-width: 2.5rem;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 0.5 * 2) solid currentcolor;
}

.caption-bottom {
  caption-side: bottom;
}

.table-sm > :not(caption) > * > * {
  padding: 0.5625rem 0.625rem calc(0.5625rem + 1px);
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}
.table-hover.table-striped > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-striped-hover-bg);
  color: var(--bs-table-striped-hover-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #000;
  --bs-table-border-color: #666666;
  --bs-table-striped-bg: #333333;
  --bs-table-striped-color: #fff;
  --bs-table-striped-hover-bg: #dddddd;
  --bs-table-striped-hover-color: #000;
  --bs-table-active-bg: #999999;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #333333;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 479.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1023.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1279.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1439.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
  font-weight: 700;
}

.is-disabled,
[disabled] .form-label {
  color: #ccc;
}

.is-required::after {
  margin-left: 0.1875rem;
  color: #f16e00;
  content: "*";
}

.form-helper {
  display: inline-block;
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  background: no-repeat center/100% url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3e%3cpath fill='%23527edb' d='M500 75C265.277 75 75 265.279 75 500s190.277 425 425 425 425-190.279 425-425S734.721 75 500 75Zm30.8 680.633a54.149 54.149 0 0 1-37.069 14.267 56.1 56.1 0 0 1-37.95-14.085q-16.233-14.079-16.226-39.384 0-22.458 15.679-37.781t38.5-15.324q22.464 0 37.789 15.324t15.324 37.781q-.003 24.951-16.047 39.202Zm133.12-330.046a162.251 162.251 0 0 1-29.23 39.38q-16.92 16.574-60.772 55.785A248.236 248.236 0 0 0 554.5 540.18a79.146 79.146 0 0 0-10.868 15.32 75.1 75.1 0 0 0-5.529 13.9q-1.953 6.954-5.879 24.42-6.762 37.068-42.413 37.069-18.541 0-31.192-12.119t-12.647-36q0-29.945 9.262-51.863a131.346 131.346 0 0 1 24.6-38.491q15.319-16.577 41.35-39.4 22.789-19.946 32.962-30.113a101.987 101.987 0 0 0 17.105-22.632 54.714 54.714 0 0 0 6.955-27.086q0-28.517-21.213-48.119t-54.7-19.6q-39.213 0-57.743 19.783t-31.359 58.272Q401.059 423.8 367.2 423.8q-19.964 0-33.683-14.079T319.8 379.248q0-33.852 21.739-68.606t63.447-57.562q41.7-22.814 97.3-22.813 51.66 0 91.244 19.069 39.549 19.074 61.119 51.856t21.571 71.286q.004 30.297-12.297 53.109Z'/%3e%3c/svg%3e");
  border: 0;
}
.form-label + .form-helper {
  margin-bottom: 0.375rem;
  vertical-align: bottom;
}

.col-form-label {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  margin-bottom: 0;
  font-size: inherit;
  font-weight: 700;
  line-height: 1.25;
}

.col-form-label-lg {
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
  font-size: 1.125rem;
}

.col-form-label-sm {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.375rem;
}

.form-control {
  display: block;
  width: 100%;
  padding: calc(0.5rem - 1px) 0.625rem calc(0.5rem + 1px);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
  color: var(--bs-body-color);
  background-color: #fff;
  background-clip: padding-box;
  border: 0.125rem solid #ccc;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  transition: border-color 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: var(--bs-body-color);
  background-color: #fff;
  border-color: currentcolor !important;
  outline: 0;
  box-shadow: none;
}
.form-control::-webkit-date-and-time-value {
  height: 1.25em;
}
.form-control::-moz-placeholder {
  color: #666;
  opacity: 1;
}
.form-control::placeholder {
  color: #666;
  opacity: 1;
}
.form-control:disabled {
  color: #666;
  background-color: #eee;
  opacity: 1;
}
.form-control::-webkit-file-upload-button {
  padding: calc(0.5rem - 1px) 0.625rem calc(0.5rem + 1px);
  margin: calc((0.5rem - 1px) * -1) -0.625rem calc((0.5rem + 1px) * -1);
  -webkit-margin-end: 0.625rem;
  margin-inline-end: 0.625rem;
  color: var(--bs-body-color);
  background-color: #fff;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 0.125rem;
  border-radius: 0;
}
.form-control::file-selector-button {
  padding: calc(0.5rem - 1px) 0.625rem calc(0.5rem + 1px);
  margin: calc((0.5rem - 1px) * -1) -0.625rem calc((0.5rem + 1px) * -1);
  -webkit-margin-end: 0.625rem;
  margin-inline-end: 0.625rem;
  color: var(--bs-body-color);
  background-color: #fff;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 0.125rem;
  border-radius: 0;
}
.form-control:disabled::-webkit-file-upload-button {
  color: #666;
  background-color: #eee;
}
.form-control:disabled::file-selector-button {
  color: #666;
  background-color: #eee;
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #f2f2f2;
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #f2f2f2;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.5rem 0;
  margin-bottom: 0;
  line-height: 1.25;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: 0.125rem 0;
}
.form-control-plaintext:focus {
  outline: 0;
  box-shadow: none;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: 1.875rem;
  padding: 0.25rem 0.625rem calc(0.25rem + 1px);
  line-height: 1.1428571429;
  font-size: 0.875rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: calc(0.25rem + 1px) 0.625rem calc(0.25rem + 3px);
  margin: calc(-0.25rem + -1px) -0.625rem calc(-0.25rem + -3px);
  -webkit-margin-end: 0.625rem;
  margin-inline-end: 0.625rem;
}
.form-control-sm::file-selector-button {
  padding: calc(0.25rem + 1px) 0.625rem calc(0.25rem + 3px);
  margin: calc(-0.25rem + -1px) -0.625rem calc(-0.25rem + -3px);
  -webkit-margin-end: 0.625rem;
  margin-inline-end: 0.625rem;
}

.form-control-lg {
  min-height: 3.125rem;
  line-height: 1.1111111111;
  font-size: 1.125rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: calc(0.8125rem - 1px) 1.25rem calc(0.8125rem + 1px);
  margin: calc((0.5rem - 1px) * -1) -0.625rem calc((0.5rem + 1px) * -1);
  -webkit-margin-end: 1.25rem;
  margin-inline-end: 1.25rem;
}
.form-control-lg::file-selector-button {
  padding: calc(0.8125rem - 1px) 1.25rem calc(0.8125rem + 1px);
  margin: calc((0.5rem - 1px) * -1) -0.625rem calc((0.5rem + 1px) * -1);
  -webkit-margin-end: 1.25rem;
  margin-inline-end: 1.25rem;
}

textarea.form-control {
  min-height: 2.5rem;
}
textarea.form-control-sm {
  min-height: 1.875rem;
}
textarea.form-control-lg {
  min-height: 3.125rem;
}

.form-control-color {
  width: 3rem;
  height: 2.5rem;
  padding: 0.5rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
}
.form-control-color.form-control-sm {
  height: 1.875rem;
}
.form-control-color.form-control-lg {
  height: 3.125rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: calc(0.5rem - 1px) 1.875rem calc(0.5rem + 1px) 0.625rem;
  -moz-padding-start: calc(0.625rem - 3px);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
  color: var(--bs-body-color);
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 7'%3e%3cpath d='M7 7 0 0h14L7 7z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.625rem top calc(50% + 1px);
  background-size: 0.875rem 1rem;
  border: 0.125rem solid #ccc;
  border-radius: 0;
  transition: border-color 0.2s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: var(--bs-body-color) !important;
  outline: 0;
  box-shadow: none;
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.625rem;
  background-image: none;
}
.form-select:disabled {
  color: #666;
  background-color: #eee;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 7'%3e%3cpath fill='%23666' d='M7 7 0 0h14L7 7z'/%3e%3c/svg%3e");
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  padding-top: calc(calc(0.25rem + 1px) - 1px);
  padding-bottom: calc(calc(0.25rem + 1px) + 1px);
  padding-left: 0.625rem;
  font-size: 0.875rem;
  line-height: 1.1428571429;
}

.form-select-lg {
  padding-top: calc(0.625rem - 1px);
  padding-bottom: 0.625rem;
  padding-left: 1.25rem;
  font-size: 1.125rem;
  line-height: 1.5;
}

.form-check {
  display: block;
  min-height: 1.25rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.125em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 0.125rem solid #ccc;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  background-color: #ff7900;
  border-color: #ff7900;
}
.form-check-input:checked {
  background-color: #ff7900;
  border-color: #ff7900;
}
.form-check-input:checked[type=checkbox] {
  background-image: var(--o-check-icon);
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23000'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #ff7900;
  border-color: #ff7900;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 3'%3e%3cpath fill='%23000' d='M0 0h10v3H0z'/%3e%3c/svg%3e");
  background-position: 50% calc(50% + 0.03125rem);
  background-size: 0.625rem 0.1875rem;
}
.form-check-input:disabled {
  pointer-events: none;
  background-color: #eee;
  filter: none;
}
.form-check-input:disabled:checked, .form-check-input:disabled:indeterminate {
  background-color: #333;
  filter: invert(1);
  border-color: #333;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #ccc;
  pointer-events: none;
  cursor: default;
}
.form-check-input:required ~ .form-check-label::after {
  margin-left: 0.1875rem;
  color: #f16e00;
  content: "*";
}

.form-switch {
  --o-switch-gradient: linear-gradient(to right, #000 1.625rem, transparent);
  min-height: 1.875rem;
  padding-left: 4.25rem;
}
.form-switch .form-check-input {
  position: relative;
  width: 3.75rem;
  height: 1.875rem;
  margin-left: -4.25rem;
  background-color: #fff;
  background-image: var(--o-close-icon), var(--o-switch-gradient);
  filter: invert(1);
  background-position: right 0.5rem top 50%, 0 0;
  background-size: 0.75rem, 1.625rem 100%;
  border-color: #fff;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus[data-focus-visible-added] {
  z-index: 5;
  isolation: isolate;
  outline: 3px solid var(--bs-focus-visible-inner-color);
  outline-offset: 2px;
  box-shadow: 0 0 0 2px var(--bs-focus-visible-outer-color);
}
.form-switch .form-check-input:checked {
  background-color: #ff7900;
  filter: none;
  border-color: #ff7900;
  background-image: var(--o-check-icon), var(--o-switch-gradient);
  background-position: 0.375rem 50%, 100% 0;
  background-size: 0.9375rem, 1.625rem 100%;
}
.form-switch .form-check-input:checked:focus[data-focus-visible-added] {
  z-index: 5;
  isolation: isolate;
  outline: 3px solid var(--bs-focus-visible-outer-color);
  outline-offset: 2px;
  box-shadow: 0 0 0 2px var(--bs-focus-visible-inner-color);
}
.form-switch .form-check-input:checked:not(:disabled) {
  --o-switch-gradient: linear-gradient(to right, #fff 1.625rem, transparent);
}
.form-switch .form-check-input:active {
  background-color: #ff7900;
  filter: none;
  border-color: #ff7900;
}
.form-switch .form-check-input:disabled {
  background-color: #333;
  filter: invert(1);
  border-color: #333;
}
.form-switch .form-check-label {
  padding-top: 0.4375rem;
}
.form-switch.form-check-reverse {
  padding-right: 4.25rem;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -4.25rem;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check + .btn {
  margin-left: 0 !important;
  color: #000;
  background-color: #fff;
  border-color: #ccc;
}
.btn-check + .btn.btn-icon:not(.btn-no-outline)::before, .btn-check + .btn.btn-icon:not(.btn-no-outline)::after {
  display: inline-block;
  width: 0.125rem;
  height: 100%;
  content: "";
}
.btn-check + .btn:not(:first-of-type) {
  border-left: 0;
}
.btn-check + .btn:not(:last-of-type) {
  border-right: 0;
}
.btn-check:hover + .btn,
.btn-check + .btn:hover {
  color: #f16e00;
  background-color: #fff;
  border-color: #ccc;
}
.btn-group .btn-check + .btn:hover {
  z-index: 0;
}
.btn-check[data-focus-visible-added]:focus + .btn,
.btn-check[data-focus-visible-added]:focus + .tag {
  z-index: 5;
  isolation: isolate;
  outline: 3px solid var(--bs-focus-visible-outer-color);
  outline-offset: 2px;
  box-shadow: 0 0 0 2px var(--bs-focus-visible-inner-color);
}
.btn-check:checked + .btn {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-check:checked + .btn-no-outline, .btn-check:checked + .btn-no-outline:hover {
  color: #f16e00;
  background-color: transparent;
}
.btn-check:checked + .tag {
  color: var(--bs-tag-active-color);
  background-color: var(--bs-tag-active-decoration-color);
  border-color: var(--bs-tag-active-decoration-color);
}
.btn-check:active + .btn,
.btn-check + .btn:active,
.btn-check + .btn.active {
  color: #000;
  background-color: #ff7900;
  border-color: #ff7900;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  color: #ccc;
  pointer-events: none;
  background-color: #fff;
  filter: none;
  border-color: currentcolor;
}
.btn-check[disabled]:checked + .btn, .btn-check:disabled:checked + .btn {
  color: #fff;
  background-color: #ccc;
  border-color: #ccc;
}
.btn-check[disabled]:checked + .btn-no-outline, .btn-check:disabled:checked + .btn-no-outline {
  color: #ccc;
  background-color: transparent;
  border-color: currentcolor;
}
.btn-check[disabled] + .tag, .btn-check:disabled + .tag {
  color: var(--bs-tag-disabled-color);
  pointer-events: none;
  border-color: var(--bs-tag-disabled-color);
}
.btn-check[disabled]:checked + .tag, .btn-check:disabled:checked + .tag {
  color: var(--bs-tag-active-color);
  background-color: var(--bs-tag-disabled-color);
}
.btn-check + .btn-no-outline:not(:only-of-type) {
  border: 0.125rem solid transparent;
}
.btn-check + .btn-no-outline:hover, .btn-check + .btn-no-outline:active, .btn-check + .btn-no-outline.active {
  color: #000;
  border-color: #ccc;
}
.btn-check + .btn-no-outline:active {
  color: #f16e00;
  background-color: transparent;
}

.form-range {
  width: 100%;
  height: calc(1rem + 4px);
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.form-range:focus {
  outline: 0;
  box-shadow: none;
}
.form-range:focus[data-focus-visible-added]::-webkit-slider-thumb {
  z-index: 5;
  isolation: isolate;
  outline: 3px solid var(--bs-focus-visible-outer-color);
  outline-offset: 2px;
  box-shadow: 0 0 0 2px var(--bs-focus-visible-inner-color);
}
.form-range:focus[data-focus-visible-added]::-moz-range-thumb {
  z-index: 5;
  isolation: isolate;
  outline: 3px solid var(--bs-focus-visible-outer-color);
  outline-offset: 2px;
  box-shadow: 0 0 0 2px var(--bs-focus-visible-inner-color);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  box-sizing: content-box;
  width: 1rem;
  height: 1rem;
  margin-top: -0.4375rem;
  cursor: -webkit-grab;
  cursor: grab;
  background-color: #fff;
  border: 0.125rem solid #000;
  border-radius: 50%;
  -webkit-transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.375rem;
  color: transparent;
  cursor: pointer;
  background-color: #eee;
  border-color: transparent;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  cursor: grab;
  background-color: #fff;
  border: 0.125rem solid #000;
  border-radius: 50%;
  -moz-transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  cursor: grabbing;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.375rem;
  color: transparent;
  cursor: pointer;
  background-color: #eee;
  border-color: transparent;
}
.form-range::-moz-range-progress {
  height: 0.375rem;
  background-color: #ff7900;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  cursor: default;
  border-color: #ccc;
}
.form-range:disabled::-moz-range-thumb {
  cursor: default;
  border-color: #ccc;
}
.form-range:disabled::-moz-range-progress {
  background-color: #ccc;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.625rem;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
  text-align: center;
  white-space: nowrap;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.8125rem 1.25rem;
  line-height: 1.1111111111;
  font-size: 1.125rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.625rem;
  line-height: 1.1428571429;
  font-size: 0.875rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 2.5rem;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -0.125rem;
}
.star-rating.disabled, .star-rating:disabled {
  pointer-events: none;
}
.star-rating > label {
  display: block;
  float: left;
  width: 1em;
  height: 1em;
  font-size: 1.5625rem;
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25'%3e%3cpath fill='%23f16e00' d='m12.5 2.9 2.379 7.35 7.721-.007-6.25 4.536 2.392 7.346-6.242-4.547-6.242 4.547 2.392-7.346-6.25-4.536 7.721.007Z'/%3e%3c/svg%3e");
}
.star-rating > label:not(:first-of-type) {
  margin-left: -0.125rem;
}
.star-rating > input:checked ~ label {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25'%3e%3cpath fill='%23ccc' d='m12.5 2.9 2.379 7.35 7.721-.007-6.25 4.536 2.392 7.346-6.242-4.547-6.242 4.547 2.392-7.346-6.25-4.536 7.721.007Z'/%3e%3c/svg%3e");
}
.star-rating > input:checked + label {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25'%3e%3cpath fill='%23f16e00' d='m12.5 2.9 2.379 7.35 7.721-.007-6.25 4.536 2.392 7.346-6.242-4.547-6.242 4.547 2.392-7.346-6.25-4.536 7.721.007Z'/%3e%3c/svg%3e");
}
.star-rating > [data-focus-visible-added]:focus + label {
  z-index: 5;
  isolation: isolate;
  outline: 3px solid var(--bs-focus-visible-outer-color);
  outline-offset: 2px;
  box-shadow: 0 0 0 2px var(--bs-focus-visible-inner-color);
  outline-offset: -1px;
  box-shadow: inset 0 0 0 3px var(--bs-focus-visible-inner-color);
}
.star-rating:hover input + label {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25'%3e%3cpath fill='%23000' d='m12.5 2.9 2.379 7.35 7.721-.007-6.25 4.536 2.392 7.346-6.242-4.547-6.242 4.547 2.392-7.346-6.25-4.536 7.721.007Z'/%3e%3c/svg%3e");
}
.star-rating > input:hover ~ label {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25'%3e%3cpath fill='%23ccc' d='m12.5 2.9 2.379 7.35 7.721-.007-6.25 4.536 2.392 7.346-6.242-4.547-6.242 4.547 2.392-7.346-6.25-4.536 7.721.007Z'/%3e%3c/svg%3e");
}
.star-rating > input:hover + label {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25'%3e%3cpath fill='%23000' d='m12.5 2.9 2.379 7.35 7.721-.007-6.25 4.536 2.392 7.346-6.242-4.547-6.242 4.547 2.392-7.346-6.25-4.536 7.721.007Z'/%3e%3c/svg%3e");
}

.star-rating-dark > label {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25'%3e%3cpath fill='%23ff7900' d='m12.5 2.9 2.379 7.35 7.721-.007-6.25 4.536 2.392 7.346-6.242-4.547-6.242 4.547 2.392-7.346-6.25-4.536 7.721.007Z'/%3e%3c/svg%3e");
}
.star-rating-dark > input:checked ~ label {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25'%3e%3cpath fill='%23666' d='m12.5 2.9 2.379 7.35 7.721-.007-6.25 4.536 2.392 7.346-6.242-4.547-6.242 4.547 2.392-7.346-6.25-4.536 7.721.007Z'/%3e%3c/svg%3e");
}
.star-rating-dark > input:checked + label {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25'%3e%3cpath fill='%23ff7900' d='m12.5 2.9 2.379 7.35 7.721-.007-6.25 4.536 2.392 7.346-6.242-4.547-6.242 4.547 2.392-7.346-6.25-4.536 7.721.007Z'/%3e%3c/svg%3e");
}
.star-rating-dark:hover input + label {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25'%3e%3cpath fill='%23fff' d='m12.5 2.9 2.379 7.35 7.721-.007-6.25 4.536 2.392 7.346-6.242-4.547-6.242 4.547 2.392-7.346-6.25-4.536 7.721.007Z'/%3e%3c/svg%3e");
}
.star-rating-dark > input:hover ~ label {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25'%3e%3cpath fill='%23666' d='m12.5 2.9 2.379 7.35 7.721-.007-6.25 4.536 2.392 7.346-6.242-4.547-6.242 4.547 2.392-7.346-6.25-4.536 7.721.007Z'/%3e%3c/svg%3e");
}
.star-rating-dark > input:hover + label {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25'%3e%3cpath fill='%23fff' d='m12.5 2.9 2.379 7.35 7.721-.007-6.25 4.536 2.392 7.346-6.242-4.547-6.242 4.547 2.392-7.346-6.25-4.536 7.721.007Z'/%3e%3c/svg%3e");
}

.star-rating-sm > label {
  font-size: 1.25rem;
}

.valid-feedback {
  display: none;
}

.was-validated :not(.quantity-selector) > .form-control:valid, :not(.quantity-selector) > .form-control.is-valid {
  padding-right: calc(1.25em + 1rem);
  background-image: var(--o-success-icon);
  background-repeat: no-repeat;
  background-position: right 0.625rem top calc(0.625rem - 2px);
  background-size: 1.25rem 1.25rem;
}

.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 3.4375rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 7'%3e%3cpath d='M7 7 0 0h14L7 7z'/%3e%3c/svg%3e"), var(--o-success-icon);
  background-position: right 0.625rem top calc(50% + 1px), center right 1.875rem;
  background-size: 0.875rem 1rem, 1.25rem 1.25rem;
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.25em + 1rem));
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.375rem;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.1428571429;
}
.invalid-feedback::before {
  flex-shrink: 0;
  align-self: center;
  width: 0.9375rem;
  height: 0.9375rem;
  margin-right: 0.3125rem;
  content: "";
  background: var(--o-error-icon) no-repeat;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: flex;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #cd3c14;
}

.was-validated .quantity-selector .form-control:invalid, .quantity-selector .form-control.is-invalid {
  border-right: none;
  border-left: none;
}
.was-validated .quantity-selector .form-control:invalid ~ button, .quantity-selector .form-control.is-invalid ~ button {
  border-color: #cd3c14;
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #cd3c14;
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  filter: none;
  border-color: #cd3c14;
}
.was-validated .form-check-input:invalid:checked, .was-validated .form-check-input:invalid:active, .form-check-input.is-invalid:checked, .form-check-input.is-invalid:active {
  background-color: #cd3c14;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.quantity-selector {
  width: 7.5rem;
}
.quantity-selector .form-control {
  max-width: 2.625rem;
  text-align: center;
  transition: none;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
.quantity-selector .form-control:not(:focus) {
  border-right: none;
  border-left: none;
}
.quantity-selector .form-control::-webkit-inner-spin-button, .quantity-selector .form-control::-webkit-outer-spin-button {
  margin: 0;
  -webkit-appearance: none;
  appearance: none;
}
.quantity-selector .form-control:disabled {
  color: #ccc;
  background-color: #fff;
}
.quantity-selector button {
  border: 0.125rem solid #ccc;
}
.quantity-selector button:first-of-type {
  min-width: 1.25rem;
  min-height: 1.25rem;
  order: -1;
  padding-right: calc(0.5rem + 2px);
  border-right: none;
}
.quantity-selector button:first-of-type::after {
  display: block;
  min-width: inherit;
  min-height: inherit;
  content: "";
  background-color: currentcolor;
  -webkit-mask: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 2'><path fill='currentColor' d='M0 0h14v2H0z'/></svg>") no-repeat 50%/0.875rem 0.125rem;
  mask: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 2'><path fill='currentColor' d='M0 0h14v2H0z'/></svg>") no-repeat 50%/0.875rem 0.125rem;
}

.quantity-selector button:first-of-type.btn-sm, .quantity-selector .btn-group-sm > button.btn:first-of-type {
  min-width: 1rem;
  min-height: 1rem;
  padding-right: calc(0.3125rem + 2px);
}
.quantity-selector button:first-of-type.btn-sm::after, .quantity-selector .btn-group-sm > button.btn:first-of-type::after {
  display: block;
  min-width: inherit;
  min-height: inherit;
  content: "";
  background-color: currentcolor;
  -webkit-mask: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 2'><path d='M0 0h10v2H0z'/></svg>") no-repeat 50%/0.625rem 0.125rem;
  mask: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 2'><path d='M0 0h10v2H0z'/></svg>") no-repeat 50%/0.625rem 0.125rem;
}

.quantity-selector button:last-of-type {
  min-width: 1.25rem;
  min-height: 1.25rem;
  padding-left: calc(0.5rem + 2px);
  border-left: none;
}
.quantity-selector button:last-of-type::after {
  display: block;
  min-width: inherit;
  min-height: inherit;
  content: "";
  background-color: currentcolor;
  -webkit-mask: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 14'><path fill='currentColor' d='M14 6H8V0H6v6H0v2h6v6h2V8h6V6z'/></svg>") no-repeat 50%/0.875rem 0.875rem;
  mask: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 14'><path fill='currentColor' d='M14 6H8V0H6v6H0v2h6v6h2V8h6V6z'/></svg>") no-repeat 50%/0.875rem 0.875rem;
}

.quantity-selector button:last-of-type.btn-sm, .quantity-selector .btn-group-sm > button.btn:last-of-type {
  min-width: 1rem;
  min-height: 1rem;
  padding-left: calc(0.3125rem + 2px);
}
.quantity-selector button:last-of-type.btn-sm::after, .quantity-selector .btn-group-sm > button.btn:last-of-type::after {
  display: block;
  min-width: inherit;
  min-height: inherit;
  content: "";
  background-color: currentcolor;
  -webkit-mask: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'><path d='M10 4H6V0H4v4H0v2h4v4h2V6h4V4z'/></svg>") no-repeat 50%/0.625rem 0.625rem;
  mask: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'><path d='M10 4H6V0H4v4H0v2h4v4h2V6h4V4z'/></svg>") no-repeat 50%/0.625rem 0.625rem;
}

.quantity-selector-sm {
  width: 5.5rem;
}
.quantity-selector-sm .form-control {
  max-width: 2.5rem;
  padding: calc(0.25rem - 1px) 0 0.25rem;
  font-size: 0.875rem;
}

.btn {
  --bs-btn-padding-x: 1.125rem;
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-font-family: inherit;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 700;
  --bs-btn-line-height: 1.25;
  --bs-btn-color: #000;
  --bs-btn-letter-spacing: -0.00625rem;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 0.125rem;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: ;
  --bs-btn-hover-border-color: initial;
  --bs-btn-box-shadow: ;
  --bs-btn-disabled-opacity: 1;
  --bs-btn-focus-box-shadow: 0 0 0 2px var(--bs-btn-focus-shadow-rgb);
  --o-icon-spacing: 0.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: calc(var(--bs-btn-padding-y) - 1px) var(--bs-btn-padding-x) calc(var(--bs-btn-padding-y) + 1px);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-decoration: none;
  letter-spacing: var(--bs-btn-letter-spacing);
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  background-color: var(--bs-btn-bg);
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}
.btn:not(.btn-icon) > svg {
  transform: translateY(1px);
}

.btn-primary,
.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ff7900;
  --bs-btn-border-color: #ff7900;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #000;
  --bs-btn-hover-border-color: #000;
  --bs-btn-focus-shadow-rgb: #fff;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #fff;
  --bs-btn-active-border-color: #000;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #ccc;
  --bs-btn-disabled-border-color: #ccc;
}
.btn-primary.btn-inverse,
.btn-warning.btn-inverse {
  --bs-btn-color: #000;
  --bs-btn-bg: #ff7900;
  --bs-btn-border-color: #ff7900;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #fff;
  --bs-btn-hover-border-color: #fff;
  --bs-btn-focus-shadow-rgb: #000;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #000;
  --bs-btn-active-border-color: #fff;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #666;
  --bs-btn-disabled-border-color: #666;
}

.btn-light,
.btn-secondary {
  --bs-btn-color: #000;
  --bs-btn-bg: #fff;
  --bs-btn-border-color: #000;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #000;
  --bs-btn-hover-border-color: #000;
  --bs-btn-focus-shadow-rgb: #fff;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ff7900;
  --bs-btn-active-border-color: #ff7900;
  --bs-btn-disabled-color: #ccc;
  --bs-btn-disabled-bg: #fff;
  --bs-btn-disabled-border-color: #ccc;
}
.btn-light.btn-inverse,
.btn-secondary.btn-inverse {
  --bs-btn-color: #fff;
  --bs-btn-bg: #000;
  --bs-btn-border-color: #fff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #fff;
  --bs-btn-hover-border-color: #fff;
  --bs-btn-focus-shadow-rgb: #000;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ff7900;
  --bs-btn-active-border-color: #ff7900;
  --bs-btn-disabled-color: #666;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #666;
}

.btn-success {
  --bs-btn-color: #000;
  --bs-btn-bg: #32c832;
  --bs-btn-border-color: #32c832;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #000;
  --bs-btn-hover-border-color: #000;
  --bs-btn-focus-shadow-rgb: #fff;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ff7900;
  --bs-btn-active-border-color: #ff7900;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #ccc;
  --bs-btn-disabled-border-color: #ccc;
}
.btn-success.btn-inverse {
  --bs-btn-color: #000;
  --bs-btn-bg: #32c832;
  --bs-btn-border-color: #32c832;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #fff;
  --bs-btn-hover-border-color: #fff;
  --bs-btn-focus-shadow-rgb: #000;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ff7900;
  --bs-btn-active-border-color: #ff7900;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #666;
  --bs-btn-disabled-border-color: #666;
}

.btn-info,
.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #000;
  --bs-btn-border-color: #000;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #fff;
  --bs-btn-hover-border-color: #000;
  --bs-btn-focus-shadow-rgb: #fff;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ff7900;
  --bs-btn-active-border-color: #ff7900;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #ccc;
  --bs-btn-disabled-border-color: #ccc;
}
.btn-info.btn-inverse,
.btn-dark.btn-inverse {
  --bs-btn-color: #000;
  --bs-btn-bg: #fff;
  --bs-btn-border-color: #fff;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #000;
  --bs-btn-hover-border-color: #fff;
  --bs-btn-focus-shadow-rgb: #000;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ff7900;
  --bs-btn-active-border-color: #ff7900;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #666;
  --bs-btn-disabled-border-color: #666;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #cd3c14;
  --bs-btn-border-color: #cd3c14;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #000;
  --bs-btn-hover-border-color: #000;
  --bs-btn-focus-shadow-rgb: #fff;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ff7900;
  --bs-btn-active-border-color: #ff7900;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #ccc;
  --bs-btn-disabled-border-color: #ccc;
}
.btn-danger.btn-inverse {
  --bs-btn-color: #fff;
  --bs-btn-bg: #cd3c14;
  --bs-btn-border-color: #cd3c14;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #fff;
  --bs-btn-hover-border-color: #fff;
  --bs-btn-focus-shadow-rgb: #000;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ff7900;
  --bs-btn-active-border-color: #ff7900;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #666;
  --bs-btn-disabled-border-color: #666;
}

.btn-no-outline {
  --bs-btn-hover-border-color: #ccc;
  --bs-btn-active-color: #f16e00;
  --bs-btn-active-border-color: #ccc;
  --bs-btn-disabled-color: #ccc;
  --bs-btn-disabled-border-color: var(--bs-btn-border-color);
}
.btn-no-outline.btn-inverse {
  --bs-btn-color: #fff;
  --bs-btn-hover-border-color: #666;
  --bs-btn-active-color: #ff7900;
  --bs-btn-active-border-color: #666;
  --bs-btn-disabled-color: #666;
}

.btn-link {
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #ccc;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  text-decoration: underline;
}
.btn-link.btn-inverse {
  --bs-btn-disabled-color: #666;
}

.btn-lg, .btn-group-lg > .btn {
  --o-icon-spacing: 0.8125rem;
  --bs-btn-padding-y: 0.8125rem;
  --bs-btn-padding-x: 1.25rem;
  --bs-btn-font-size: 1.125rem;
  --bs-btn-line-height: 1.1111111111;
  --bs-btn-letter-spacing: -0.0125rem;
  --bs-btn-border-radius: ;
}

.btn-sm, .btn-group-sm > .btn {
  --o-icon-spacing: 0.3125rem;
  --bs-btn-padding-y: 0.3125rem;
  --bs-btn-padding-x: 0.625rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-line-height: 1.1428571429;
  --bs-btn-letter-spacing: -0.00625rem;
  --bs-btn-border-radius: ;
}

.btn-icon {
  padding: var(--o-icon-spacing);
}

.btn-social {
  --bs-btn-border-color: currentcolor;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--o-network-color, $black);
  --bs-btn-hover-border-color: var(--o-network-color, $black);
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #000;
  --bs-btn-active-border-color: #000;
  --bs-btn-disabled-color: #ccc;
  --bs-btn-disabled-bg: transparent;
  border-radius: 50%;
  min-width: 1.25rem;
  min-height: 1.25rem;
}
.btn-social::before {
  display: block;
  min-width: inherit;
  min-height: inherit;
  content: "";
  background-color: currentcolor;
  -webkit-mask: var(--o-network-logo) no-repeat 50%/1.25rem 1.25rem;
  mask: var(--o-network-logo) no-repeat 50%/1.25rem 1.25rem;
}

.btn-social.btn-inverse {
  --bs-btn-color: #fff;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #fff;
  --bs-btn-active-border-color: #fff;
  --bs-btn-disabled-color: #666;
}

.btn-facebook {
  --o-network-color: #3b5998;
  --o-network-logo: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M19 6h5V0h-5c-4 0-7 3-7 7v3H8v6h4v16h6V16h5l1-6h-6V7l1-1z'%3e%3c/path%3e%3c/svg%3e");
}
.btn-twitter {
  --o-network-color: #1da1f2;
  --o-network-logo: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M32 7a13 13 0 01-3.8 1.1 6.6 6.6 0 003-3.6c-1.4.7-2.8 1.3-4.3 1.6a6.6 6.6 0 00-11.1 6A18.6 18.6 0 012.2 5a6.6 6.6 0 002 8.9c-1 0-2-.4-3-.9v.1c0 3.2 2.4 5.9 5.4 6.5a6.6 6.6 0 01-3 0 6.6 6.6 0 006.1 4.6A13.2 13.2 0 010 27.1a18.6 18.6 0 0028.7-16.6C30 9.5 31.1 8.4 32 7z'/%3e%3c/svg%3e");
}
.btn-instagram {
  --o-network-color: #e1306c;
  --o-network-logo: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M16 2.9h6.5c1.5.1 2.4.4 3 .6a5 5 0 011.8 1.2c.5.6.9 1.1 1.2 1.9.2.5.4 1.4.5 3a112.7 112.7 0 01-.5 15.8 5 5 0 01-1.2 1.9c-.6.5-1.1.9-1.9 1.2-.5.2-1.4.4-3 .5a112.7 112.7 0 01-15.8-.5 5 5 0 01-1.9-1.2 5 5 0 01-1.2-1.9c-.2-.5-.4-1.4-.5-3a112.7 112.7 0 01.5-15.8 5 5 0 011.2-1.9c.6-.5 1.1-.9 1.9-1.2C7 3.3 8 3 9.6 3l6.4-.1zM16 0H9.4C7.7.3 6.5.5 5.5.9s-2 1-2.8 1.9c-1 .9-1.5 1.8-1.9 2.8-.4 1-.6 2.2-.7 3.9a117.6 117.6 0 00.7 17c.5 1.1 1 2 1.9 3 .9.8 1.8 1.4 2.8 1.8 1 .4 2.2.6 3.9.7a117.2 117.2 0 0017-.7c1.1-.4 2-1 2.9-1.9s1.4-1.8 1.8-2.8c.4-1 .7-2.2.8-3.9a117.2 117.2 0 00-.8-17A7.8 7.8 0 0026.4.8c-1-.5-2.1-.7-3.8-.8L16 0z'/%3e%3cpath d='M16 7.8a8.2 8.2 0 100 16.4 8.2 8.2 0 000-16.4zm0 13.5a5.3 5.3 0 110-10.6 5.3 5.3 0 010 10.6zM26.5 7.5a2 2 0 11-3.9 0 2 2 0 013.9 0z'/%3e%3c/svg%3e");
}
.btn-youtube {
  --o-network-color: #f00;
  --o-network-logo: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M31.7 9.6s-.3-2.2-1.3-3.2c-1.2-1.3-2.6-1.3-3.2-1.3-4.5-.4-11.2-.4-11.2-.4s-6.7 0-11.2.4c-.6 0-2 0-3.2 1.3C.6 7.4.3 9.6.3 9.6S0 12.2 0 14.8v2.4c0 2.6.3 5.2.3 5.2s.3 2.2 1.3 3.2c1.2 1.2 2.8 1.2 3.5 1.3 2.6.3 11 .4 11 .4s6.6 0 11.1-.4c.6 0 2 0 3.2-1.3 1-1 1.3-3.2 1.3-3.2s.3-2.6.3-5.2v-2.4c0-2.6-.3-5.2-.3-5.2zm-19 10.5v-9l8.6 4.6-8.6 4.4z'/%3e%3c/svg%3e");
}
.btn-linkedin {
  --o-network-color: #0077b5;
  --o-network-logo: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M12 12h5.5v2.8h.1a6.1 6.1 0 015.5-2.8c5.8 0 6.9 3.6 6.9 8.4V30h-5.8v-8.5c0-2 0-4.7-3-4.7s-3.4 2.2-3.4 4.5V30H12V12zM2 12h6v18H2V12zm6-5a3 3 0 11-6 0 3 3 0 016 0z'/%3e%3c/svg%3e");
}
.btn-linkedin::before {
  transform: translateY(-1px);
}

.btn-whatsapp {
  --o-network-color: #25d366;
  --o-network-logo: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M27.3 4.7a15.9 15.9 0 00-25 19.1L.1 32l8.4-2.2A15.9 15.9 0 0027.3 4.7zM16 29c-2.4 0-4.7-.6-6.7-1.8l-.5-.3-5 1.3 1.3-4.8-.3-.5A13.2 13.2 0 1116.1 29zm7.2-9.8l-2.7-1.3c-.3-.1-.6-.2-1 .2l-1.2 1.5c-.2.3-.4.3-.8.1s-1.7-.6-3.2-2c-1.2-1-2-2.3-2.2-2.7s0-.6.2-.8l.6-.7.4-.6v-.7l-1.3-3c-.3-.7-.6-.6-.9-.7h-.7c-.2 0-.7.1-1.1.5C9 9.4 8 10.4 8 12.3s1.4 3.9 1.6 4.1c.2.3 2.8 4.3 6.8 6l2.3.9c.9.3 1.8.2 2.4.1.8-.1 2.4-1 2.7-1.9s.4-1.7.3-1.9l-.8-.4z'/%3e%3c/svg%3e");
}
.btn-mail {
  --o-network-color: #ff7900;
  --o-network-logo: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M3.2 14.3c0 9.5 0 9 .2 9.5.3.8 1 1.4 1.7 1.7l12.2.1h11.5v-8.8c0-9.3 0-8.9-.2-9.3-.2-.7-.7-1.2-1.3-1.6l-.8-.3H3.2v8.7zm22.9-2.4a246.2 246.2 0 01-4.9 4.7l-.8.7-.5.6-.7.6c-.6.6-1 .9-1.3 1a4 4 0 01-1.8.5 4 4 0 01-2.4-.6 13 13 0 01-1.9-1.7l-2.4-2.4-.6-.6-1.4-1.3L6.1 12l-.5-.5V8.9l.6.5L7.9 11l1.4 1.4 1.3 1.2 1.3 1.3a195 195 0 012.6 2.4c.4.3 1 .5 1.6.4.5 0 1-.1 1.4-.4L19 16l1-1 1-1a214.7 214.7 0 012.2-2l1-1 2-2 .2-.2v2.8l-.3.3z'/%3e%3c/svg%3e");
}
.btn-mail::before {
  -webkit-mask-size: 1.5rem;
  mask-size: 1.5rem;
}

.btn-snapchat {
  --o-network-color: #fffc00;
  --o-network-logo: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 28'%3e%3cpath d='M13 2c3 0 5 2 7 4v6h2l1 1-3 2v1l4 4h1l1 1-4 1-1 2h-2-1c-1 0-2 2-5 2s-4-2-5-2H5l-1-2-4-1 1-1h1l4-4v-1l-3-2 1-1h2V9 6c2-3 4-4 7-4z'/%3e%3c/svg%3e");
}
.btn-pinterest {
  --o-network-color: red;
  --o-network-logo: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M16 2a14 14 0 00-5 27v-4l2-7-1-2c0-2 1-3 3-3l1 2-1 4c0 2 1 3 2 3 3 0 5-3 5-7 0-3-3-5-6-5-4 0-6 3-6 6l1 3a302 302 0 01-1 2c-2-1-3-3-3-5 0-5 3-9 9-9 5 0 9 4 9 8 0 5-3 9-7 9l-4-2v4l-2 3a14 14 0 0018-13c0-8-6-14-14-14z'/%3e%3c/svg%3e");
}
.btn-pinterest::before {
  -webkit-mask-size: 1.375rem;
  mask-size: 1.375rem;
}

.fade {
  transition: opacity ease-in-out linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  --bs-btn-padding-x: 0.625rem;
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  align-self: center;
  margin-left: 0.625rem;
  content: "";
  border-top: 0.4375rem solid;
  border-right: 0.4375rem solid transparent;
  border-bottom: 0;
  border-left: 0.4375rem solid transparent;
  transform: translateY(0.0625rem);
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropdown-toggle:not(.dropdown-toggle-split) {
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: #fff;
  --bs-btn-border-color: var(--bs-border-color-translucent);
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #fff;
  --bs-btn-hover-border-color: var(--bs-border-color-translucent);
  --bs-btn-focus-shadow-rgb: #fff;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #fff;
  --bs-btn-active-border-color: #000;
  --bs-btn-disabled-color: #ccc;
  --bs-btn-disabled-bg: #fff;
  --bs-btn-disabled-border-color: #ccc;
}
.dropdown-toggle:not(.dropdown-toggle-split).btn-inverse {
  --bs-btn-color: #fff;
  --bs-btn-bg: #000;
  --bs-btn-border-color: #666;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #000;
  --bs-btn-hover-border-color: #666;
  --bs-btn-focus-shadow-rgb: #fff;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #000;
  --bs-btn-active-border-color: #fff;
  --bs-btn-disabled-color: #666;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #666;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-y: 0;
  --bs-dropdown-spacer: 0;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-line-height: 1.125;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: ;
  --bs-dropdown-border-width: 0.125rem;
  --bs-dropdown-inner-border-radius: 0;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.3125rem;
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #000;
  --bs-dropdown-link-hover-color: #000;
  --bs-dropdown-link-hover-bg: #ccc;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #000;
  --bs-dropdown-link-disabled-color: #ccc;
  --bs-dropdown-item-padding-x: 0.625rem;
  --bs-dropdown-item-padding-y: 0.625rem;
  --bs-dropdown-item-font-weight: 700;
  --bs-dropdown-header-color: #000;
  --bs-dropdown-header-padding-x: 0.625rem;
  --bs-dropdown-header-padding-y: 1.25rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-y);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  line-height: var(--bs-dropdown-line-height);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}
.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 480px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1024px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1280px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1440px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  align-self: center;
  margin-left: 0.625rem;
  content: "";
  border-top: 0;
  border-right: 0.4375rem solid transparent;
  border-bottom: 0.4375rem solid;
  border-left: 0.4375rem solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  align-self: center;
  margin-left: 0.625rem;
  content: "";
  border-top: 0.4375rem solid transparent;
  border-right: 0;
  border-bottom: 0.4375rem solid transparent;
  border-left: 0.4375rem solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  align-self: center;
  margin-left: 0.625rem;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  position: static !important;
  display: inline-block;
  align-self: center;
  margin-right: 0.625rem;
  color: unset !important;
  content: "";
  border-top: 0.4375rem solid transparent;
  border-right: 0.4375rem solid;
  border-bottom: 0.4375rem solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: var(--bs-dropdown-border-width) solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x) calc(var(--bs-dropdown-item-padding-y) + 0.125rem);
  clear: both;
  font-weight: var(--bs-dropdown-item-font-weight);
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}
.dropdown-item:focus[data-focus-visible-added] {
  outline-offset: -3px;
  box-shadow: inset 0 0 0 5px var(--bs-focus-visible-inner-color);
}
.dropdown-item:focus[data-focus-visible-added].active {
  outline-color: var(--bs-focus-visible-inner-color);
  box-shadow: inset 0 0 0 5px var(--bs-focus-visible-outer-color);
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #fff;
  --bs-dropdown-bg: #000;
  --bs-dropdown-border-color: #666;
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #fff;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: #666;
  --bs-dropdown-link-hover-bg: #666;
  --bs-dropdown-link-active-color: #000;
  --bs-dropdown-link-active-bg: #fff;
  --bs-dropdown-link-disabled-color: #666;
  --bs-dropdown-header-color: #fff;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}
.btn-group:focus-within,
.btn-group-vertical:focus-within {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: -0.125rem;
}
.dropdown-toggle-split {
  min-width: 2.125rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  border-color: currentcolor;
}
.btn-group:not(.dropstart) .dropdown-toggle-split:not(:hover):not(:focus):not(:active), .dropstart .dropdown-toggle-split ~ .btn:not(:hover):not(:focus):not(:active) {
  border-left-color: transparent;
}
.btn-group:not(.dropstart) .dropdown-toggle-split:not(:hover):not(:focus):not(:active)::before, .dropstart .dropdown-toggle-split ~ .btn:not(:hover):not(:focus):not(:active)::before {
  position: absolute;
  top: -0.125rem;
  bottom: -0.125rem;
  left: -0.125rem;
  width: 0.125rem;
  color: inherit;
  content: "";
  background: linear-gradient(currentcolor 0.125rem, transparent 0.125rem, transparent 0.3125rem, currentcolor 0.3125rem, currentcolor calc(100% - 0.3125rem), transparent calc(100% - 0.3125rem), transparent calc(100% - 0.125rem), currentcolor calc(100% - 0.125rem));
}
.btn:active + .dropdown-toggle-split, .btn.active + .dropdown-toggle-split, .dropdown-toggle-split:active + * + .btn, .dropdown-toggle-split.active + * + .btn {
  border-color: #ff7900;
}
.btn-group.show > .dropdown-toggle-split:not(:focus):not(:active)::before {
  color: #ff7900;
  background-color: currentcolor;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.3125rem;
  padding-left: 0.3125rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -0.125rem;
}
.nav {
  --bs-nav-link-padding-x: 1.25rem;
  --bs-nav-link-padding-y: 0.625rem;
  --bs-nav-link-font-weight: 700;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #ccc;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: flex;
  align-items: center;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: none;
}
.nav-link:hover {
  color: var(--bs-nav-link-hover-color);
}
.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 0.125rem;
  --bs-nav-tabs-border-color: #000;
  --bs-nav-tabs-border-radius: ;
  --bs-nav-tabs-link-padding-x: 1.8125rem;
  --bs-nav-tabs-link-hover-color: #fff;
  --bs-nav-tabs-link-hover-bg: var(--bs-nav-tabs-link-hover-border-color);
  --bs-nav-tabs-link-border-width: var(--bs-nav-tabs-border-width) var(--bs-nav-tabs-border-width) 0;
  --bs-nav-tabs-link-hover-border-color: #000;
  --bs-nav-tabs-link-active-color: #000;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #000;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
@media (min-width: 1024px) {
  .nav-tabs .nav-item + .nav-item {
    margin-left: var(--bs-nav-link-padding-y);
  }
}
.nav-tabs .nav-link {
  padding: calc(1rem - (var(--bs-nav-tabs-border-width))) calc(var(--bs-nav-tabs-link-padding-x) - (var(--bs-nav-tabs-border-width)));
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  background: none;
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-width: var(--bs-nav-tabs-link-border-width);
}
.nav-tabs .nav-link:hover {
  color: var(--bs-nav-tabs-link-hover-color);
  background-color: var(--bs-nav-tabs-link-hover-bg);
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
}

.nav-tabs-light {
  --bs-nav-tabs-border-width: 0.0625rem;
  --bs-nav-tabs-border-color: #ccc;
  --bs-nav-tabs-link-hover-color: #f16e00;
  --bs-nav-tabs-link-border-width: 0 0 calc(var(--bs-nav-tabs-border-width) * 4);
  --bs-nav-tabs-link-hover-border-color: transparent;
  --bs-nav-tabs-link-active-border-color: #f16e00;
}

.nav-pills {
  --bs-nav-pills-padding-x: 1.8125rem;
  --bs-nav-pills-border-radius: ;
  --bs-nav-pills-link-active-color: #000;
  --bs-nav-pills-link-active-bg: #ff7900;
}
.nav-pills .nav-link {
  padding-right: var(--bs-nav-pills-padding-x);
  padding-left: var(--bs-nav-pills-padding-x);
  background: none;
  border: 0;
}
.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}
.nav-pills .nav-item + .nav-item {
  margin-left: calc(var(--bs-nav-link-padding-y) * 0.5); /* stylelint-disable-line function-disallowed-list */
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content {
  --bs-tab-content-padding-y: 1.25rem;
  --bs-tab-content-padding-x: calc(1.8125rem - (var(--bs-tab-content-border-width)));
  --bs-tab-content-border-width: 0.125rem;
  padding: var(--bs-tab-content-padding-y) var(--bs-tab-content-padding-x);
  border: var(--bs-tab-content-border-width) solid;
  border-top: 0;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .tab-pane-with-nested-tab {
  display: none;
  margin: calc(-1 * var(--bs-tab-content-padding-y)) calc(-1 * var(--bs-tab-content-padding-x));
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0;
  --bs-navbar-font-weight: 700;
  --bs-navbar-border-width: 0.0625rem;
  --bs-navbar-border-color: #ccc;
  --bs-navbar-color: #000;
  --bs-navbar-hover-color: #f16e00;
  --bs-navbar-disabled-color: #ccc;
  --bs-navbar-active-color: #f16e00;
  --bs-navbar-brand-padding-y: 0;
  --bs-navbar-brand-margin-y: 0.625rem;
  --bs-navbar-brand-margin-end: 1.875rem;
  --bs-navbar-brand-logo-size: 1.875rem;
  --bs-navbar-brand-font-size: 1.3125rem;
  --bs-navbar-brand-letter-spacing: -0.03125rem;
  --bs-navbar-brand-color: #f16e00;
  --bs-navbar-brand-hover-color: #f16e00;
  --bs-navbar-nav-padding-top: 0;
  --bs-navbar-nav-padding-x: 0;
  --bs-navbar-nav-padding-bottom: 0;
  --bs-navbar-nav-font-size: 1rem;
  --bs-navbar-nav-line-height: 1.125;
  --bs-navbar-nav-letter-spacing: -0.00625rem;
  --bs-navbar-nav-link-padding-y: 1rem;
  --bs-navbar-nav-link-padding-x: 0.3125rem;
  --bs-navbar-nav-icon-padding-y: 0.75rem;
  --bs-navbar-nav-icon-padding-x: 0.9375rem;
  --bs-navbar-nav-icon-size: 1.5625rem;
  --bs-navbar-toggler-padding-y: 0.75rem;
  --bs-navbar-toggler-padding-x: 0.9375rem;
  --bs-navbar-toggler-font-size: 1.04166666rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25' fill='%23000'%3e%3cpath d='M2 19h21v-2H2v2Zm0-6h21v-2H2v2Zm0-6h21V5H2v2Z'/%3e%3c/svg%3e");
  --bs-navbar-toggler-icon-filter: none;
  --bs-navbar-toggler-border-color: ;
  --bs-navbar-toggler-border-radius: ;
  --bs-navbar-toggler-focus-width: ;
  --bs-navbar-toggler-transition: ;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
  font-weight: var(--bs-navbar-font-weight);
  box-shadow: inset 0 calc(-1 * var(--bs-navbar-border-width)) 0 var(--bs-navbar-border-color); /* stylelint-disable-line function-disallowed-list */
  transition: padding-top 0.2s ease-in-out, padding-bottom 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar {
    transition: none;
  }
}
@media (min-width: 768px) {
  .navbar {
    --bs-navbar-padding-y: 0.375rem;
    --bs-navbar-brand-margin-y: 1.1875rem;
    --bs-navbar-brand-logo-size: 3.125rem;
    --bs-navbar-brand-font-size: 2.1875rem;
    --bs-navbar-brand-letter-spacing: -0.0625rem;
    --bs-navbar-nav-link-padding-x: 0.625rem;
    --bs-navbar-nav-icon-padding-y: 1.1875rem;
    --bs-navbar-nav-icon-padding-x: 1.25rem;
    --bs-navbar-nav-icon-size: 1.875rem;
    --bs-navbar-toggler-padding-y: 1.1875rem;
    --bs-navbar-toggler-padding-x: 1.25rem;
    --bs-navbar-toggler-font-size: 1.25rem;
    --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30' fill='%23000'%3e%3cpath d='M28 21v2H2v-2h26Zm0-7v2H2v-2h26Zm0-7v2H2V7h26Z'/%3e%3c/svg%3e");
  }
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-xs,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: flex-end;
}
.navbar-brand {
  position: relative;
  display: inline-flex;
  align-items: flex-end;
  order: -1;
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-top: var(--bs-navbar-brand-margin-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  margin-bottom: var(--bs-navbar-brand-margin-y);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
  transition: margin 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-brand {
    transition: none;
  }
}
.navbar-brand:hover {
  color: var(--bs-navbar-brand-hover-color);
}
.navbar-brand img {
  width: auto;
  height: var(--bs-navbar-brand-logo-size);
  transition: width 0.2s ease-in-out, height 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-brand img {
    transition: none;
  }
}
.navbar-brand .title,
.navbar-brand .two-lined {
  margin: -0.2857142857em 1.25rem -0.1428571429em calc(var(--bs-navbar-brand-logo-size) / 2); /* stylelint-disable-line function-disallowed-list */
  font-size: var(--bs-navbar-brand-font-size);
  line-height: 1;
  letter-spacing: var(--bs-navbar-brand-letter-spacing);
}
.navbar-brand .two-lined {
  --bs-navbar-brand-font-size: 1.0625rem;
  --bs-navbar-brand-letter-spacing: -0.025rem;
}
@media (min-width: 768px) {
  .navbar-brand .two-lined {
    --bs-navbar-brand-font-size: 1.8125rem;
    --bs-navbar-brand-letter-spacing: -0.05rem;
  }
}

.navbar-nav {
  --bs-nav-link-padding-x: var(--bs-navbar-nav-link-padding-x);
  --bs-nav-link-padding-y: var(--bs-navbar-nav-link-padding-y);
  --bs-nav-link-font-size: var(--bs-navbar-nav-font-size, 1rem);
  --bs-nav-link-font-weight: 700;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding: var(--bs-navbar-nav-padding-top) var(--bs-navbar-nav-padding-x) var(--bs-navbar-nav-padding-bottom);
  margin-bottom: 0;
  font-size: var(--bs-navbar-nav-font-size);
  line-height: var(--bs-navbar-nav-line-height, 1.125);
  letter-spacing: var(--bs-navbar-nav-letter-spacing, -0.00625rem);
  list-style: none;
}
.navbar-nav .nav-link {
  position: relative;
  padding-top: calc(var(--bs-navbar-nav-link-padding-y) - (var(--bs-navbar-border-width)));
  margin-left: calc(-1 * var(--bs-navbar-nav-link-padding-x)); /* stylelint-disable-line function-disallowed-list */
  word-break: break-all;
}
.navbar-nav .nav-link:hover {
  z-index: 1;
}
@media (max-width: 1023.98px) {
  .navbar-nav .nav-link {
    outline-offset: -3px;
  }
}
.navbar-nav .nav-item {
  border-top: var(--bs-navbar-border-width) solid var(--bs-navbar-border-color);
}
.navbar-nav .nav-item .badge {
  --bs-badge-padding: 0.125rem 0.375rem;
  margin-top: 0.375rem;
}
.navbar-nav .nav-item .nav-link.nav-icon {
  padding: var(--bs-navbar-nav-icon-padding-y) var(--bs-navbar-nav-icon-padding-x);
  margin-left: calc(-1 * var(--bs-navbar-nav-icon-padding-x)); /* stylelint-disable-line function-disallowed-list */
  white-space: nowrap;
}
.navbar-nav .nav-item .nav-link.nav-icon svg,
.navbar-nav .nav-item .nav-link.nav-icon img {
  width: var(--bs-navbar-nav-icon-size);
  height: var(--bs-navbar-nav-icon-size);
}
.navbar-nav .nav-item:not(:first-of-type) > .nav-icon {
  margin-left: 0;
}
.navbar-nav.flex-row .nav-item {
  border: 0;
}
.navbar-nav .show > .nav-link,
.navbar-nav .nav-link.active {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-bottom: var(--bs-navbar-nav-link-padding-y);
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover {
  color: var(--bs-navbar-active-color);
}

.navbar-item {
  margin-bottom: var(--bs-navbar-nav-icon-padding-y);
}

.navbar-collapse.show,
.navbar-collapse.collapsing {
  flex-basis: 100%;
  align-items: center;
}
.navbar-collapse.show[class*=d-],
.navbar-collapse.collapsing[class*=d-] {
  border-top: var(--bs-navbar-border-width) solid var(--bs-navbar-border-color);
}
@media (min-width: 768px) {
  .navbar-collapse.show[class*=d-],
.navbar-collapse.collapsing[class*=d-] {
    margin-bottom: calc(-1 * var(--bs-navbar-padding-y)); /* stylelint-disable-line function-disallowed-list */
  }
}
@media (min-width: 768px) {
  .navbar-collapse.show:first-of-type,
.navbar-collapse.collapsing:first-of-type {
    margin-top: var(--bs-navbar-padding-y);
  }
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) calc(var(--bs-navbar-toggler-padding-x) / 2) var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x); /* stylelint-disable-line function-disallowed-list */
  margin-right: calc(-0.5 * var(--bs-navbar-toggler-padding-x)); /* stylelint-disable-line function-disallowed-list */
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: 0;
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
}
.navbar-toggler:hover, .navbar-toggler:active {
  --bs-navbar-toggler-icon-filter: invert(46%) sepia(60%) saturate(2878%) hue-rotate(6deg) brightness(98%) contrast(104%);
}
.navbar-toggler[aria-expanded=true] {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30' fill='%23000'%3e%3cpath d='m15 17.121-8.132 8.132-2.121-2.12L12.879 15 4.747 6.868l2.12-2.121L15 12.879l8.132-8.132 2.12 2.121L17.122 15l8.132 8.132-2.121 2.12L15 17.123z'/%3e%3c/svg%3e");
}
.navbar-toggler.collapsed {
  order: 7;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  filter: var(--bs-navbar-toggler-icon-filter);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

.navbar:not(.supra) + .navbar {
  padding: 0;
  margin-top: calc(-1 * var(--bs-navbar-border-width));
}

@media (min-width: 480px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    margin-left: 0;
  }
  .navbar-expand-sm .navbar-nav .nav-link.active::before {
    position: absolute;
    right: var(--bs-navbar-nav-link-padding-x);
    bottom: calc(-1 * var(--bs-navbar-padding-y));
    left: var(--bs-navbar-nav-link-padding-x);
    height: calc(var(--bs-navbar-border-width) * 4);
    content: "";
    background-color: currentcolor;
    transition: bottom 0.2s ease-in-out;
  }
}
@media (min-width: 480px) and (prefers-reduced-motion: reduce) {
  .navbar-expand-sm .navbar-nav .nav-link.active::before {
    transition: none;
  }
}
@media (min-width: 480px) {
  .navbar-expand-sm .navbar-nav .nav-item {
    border: 0;
  }
  .navbar-expand-sm .navbar-nav .nav-item:first-child > .nav-link {
    margin-left: calc(-1 * var(--bs-navbar-nav-link-padding-x)); /* stylelint-disable-line function-disallowed-list */
  }
  .navbar-expand-sm .navbar-nav .nav-item:last-child > .nav-link {
    margin-right: calc(-1 * var(--bs-navbar-nav-link-padding-x)); /* stylelint-disable-line function-disallowed-list */
  }
  .navbar-expand-sm .navbar-nav .nav-item:first-child > .nav-icon {
    margin-left: calc(-1 * var(--bs-navbar-nav-icon-padding-x)); /* stylelint-disable-line function-disallowed-list */
  }
  .navbar-expand-sm .navbar-nav .nav-item:last-child > .nav-icon {
    margin-right: calc(-1 * var(--bs-navbar-nav-icon-padding-x)); /* stylelint-disable-line function-disallowed-list */
  }
}
@media (min-width: 480px) {
  .navbar-expand-sm .navbar-collapse.show[class*=d-],
.navbar-expand-sm .navbar-collapse.collapsing[class*=d-] {
    margin-bottom: 0;
    border-top: 0;
  }
  .navbar-expand-sm .navbar-collapse.show:first-of-type,
.navbar-expand-sm .navbar-collapse.collapsing:first-of-type {
    margin-top: 0;
  }
}
@media (min-width: 480px) {
  .navbar-expand-sm.supra {
    display: flex;
  }
}
@media (min-width: 480px) {
  .navbar-expand-sm.header-minimized {
    --bs-navbar-nav-link-padding-y: .75rem;
  }
}
@media (min-width: 480px) {
  .navbar-expand-sm:not(.supra) + .navbar {
    margin-top: 0;
  }
  .navbar-expand-sm:not(.supra) + .navbar .nav-link {
    padding-bottom: calc(var(--bs-navbar-nav-link-padding-y) + 0.0625rem);
  }
  .navbar-expand-sm:not(.supra) + .navbar .nav-link.active::before {
    bottom: 0;
  }
}
@media (min-width: 480px) {
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
}
@media (min-width: 480px) {
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
}
@media (min-width: 480px) {
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (min-width: 480px) {
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    margin-left: 0;
  }
  .navbar-expand-md .navbar-nav .nav-link.active::before {
    position: absolute;
    right: var(--bs-navbar-nav-link-padding-x);
    bottom: calc(-1 * var(--bs-navbar-padding-y));
    left: var(--bs-navbar-nav-link-padding-x);
    height: calc(var(--bs-navbar-border-width) * 4);
    content: "";
    background-color: currentcolor;
    transition: bottom 0.2s ease-in-out;
  }
}
@media (min-width: 768px) and (prefers-reduced-motion: reduce) {
  .navbar-expand-md .navbar-nav .nav-link.active::before {
    transition: none;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md .navbar-nav .nav-item {
    border: 0;
  }
  .navbar-expand-md .navbar-nav .nav-item:first-child > .nav-link {
    margin-left: calc(-1 * var(--bs-navbar-nav-link-padding-x)); /* stylelint-disable-line function-disallowed-list */
  }
  .navbar-expand-md .navbar-nav .nav-item:last-child > .nav-link {
    margin-right: calc(-1 * var(--bs-navbar-nav-link-padding-x)); /* stylelint-disable-line function-disallowed-list */
  }
  .navbar-expand-md .navbar-nav .nav-item:first-child > .nav-icon {
    margin-left: calc(-1 * var(--bs-navbar-nav-icon-padding-x)); /* stylelint-disable-line function-disallowed-list */
  }
  .navbar-expand-md .navbar-nav .nav-item:last-child > .nav-icon {
    margin-right: calc(-1 * var(--bs-navbar-nav-icon-padding-x)); /* stylelint-disable-line function-disallowed-list */
  }
}
@media (min-width: 768px) {
  .navbar-expand-md .navbar-collapse.show[class*=d-],
.navbar-expand-md .navbar-collapse.collapsing[class*=d-] {
    margin-bottom: 0;
    border-top: 0;
  }
  .navbar-expand-md .navbar-collapse.show:first-of-type,
.navbar-expand-md .navbar-collapse.collapsing:first-of-type {
    margin-top: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md.supra {
    display: flex;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md.header-minimized {
    --bs-navbar-nav-link-padding-y: .75rem;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md:not(.supra) + .navbar {
    margin-top: 0;
  }
  .navbar-expand-md:not(.supra) + .navbar .nav-link {
    padding-bottom: calc(var(--bs-navbar-nav-link-padding-y) + 0.0625rem);
  }
  .navbar-expand-md:not(.supra) + .navbar .nav-link.active::before {
    bottom: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1024px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    margin-left: 0;
  }
  .navbar-expand-lg .navbar-nav .nav-link.active::before {
    position: absolute;
    right: var(--bs-navbar-nav-link-padding-x);
    bottom: calc(-1 * var(--bs-navbar-padding-y));
    left: var(--bs-navbar-nav-link-padding-x);
    height: calc(var(--bs-navbar-border-width) * 4);
    content: "";
    background-color: currentcolor;
    transition: bottom 0.2s ease-in-out;
  }
}
@media (min-width: 1024px) and (prefers-reduced-motion: reduce) {
  .navbar-expand-lg .navbar-nav .nav-link.active::before {
    transition: none;
  }
}
@media (min-width: 1024px) {
  .navbar-expand-lg .navbar-nav .nav-item {
    border: 0;
  }
  .navbar-expand-lg .navbar-nav .nav-item:first-child > .nav-link {
    margin-left: calc(-1 * var(--bs-navbar-nav-link-padding-x)); /* stylelint-disable-line function-disallowed-list */
  }
  .navbar-expand-lg .navbar-nav .nav-item:last-child > .nav-link {
    margin-right: calc(-1 * var(--bs-navbar-nav-link-padding-x)); /* stylelint-disable-line function-disallowed-list */
  }
  .navbar-expand-lg .navbar-nav .nav-item:first-child > .nav-icon {
    margin-left: calc(-1 * var(--bs-navbar-nav-icon-padding-x)); /* stylelint-disable-line function-disallowed-list */
  }
  .navbar-expand-lg .navbar-nav .nav-item:last-child > .nav-icon {
    margin-right: calc(-1 * var(--bs-navbar-nav-icon-padding-x)); /* stylelint-disable-line function-disallowed-list */
  }
}
@media (min-width: 1024px) {
  .navbar-expand-lg .navbar-collapse.show[class*=d-],
.navbar-expand-lg .navbar-collapse.collapsing[class*=d-] {
    margin-bottom: 0;
    border-top: 0;
  }
  .navbar-expand-lg .navbar-collapse.show:first-of-type,
.navbar-expand-lg .navbar-collapse.collapsing:first-of-type {
    margin-top: 0;
  }
}
@media (min-width: 1024px) {
  .navbar-expand-lg.supra {
    display: flex;
  }
}
@media (min-width: 1024px) {
  .navbar-expand-lg.header-minimized {
    --bs-navbar-nav-link-padding-y: .75rem;
  }
}
@media (min-width: 1024px) {
  .navbar-expand-lg:not(.supra) + .navbar {
    margin-top: 0;
  }
  .navbar-expand-lg:not(.supra) + .navbar .nav-link {
    padding-bottom: calc(var(--bs-navbar-nav-link-padding-y) + 0.0625rem);
  }
  .navbar-expand-lg:not(.supra) + .navbar .nav-link.active::before {
    bottom: 0;
  }
}
@media (min-width: 1024px) {
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
}
@media (min-width: 1024px) {
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
}
@media (min-width: 1024px) {
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (min-width: 1024px) {
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1280px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    margin-left: 0;
  }
  .navbar-expand-xl .navbar-nav .nav-link.active::before {
    position: absolute;
    right: var(--bs-navbar-nav-link-padding-x);
    bottom: calc(-1 * var(--bs-navbar-padding-y));
    left: var(--bs-navbar-nav-link-padding-x);
    height: calc(var(--bs-navbar-border-width) * 4);
    content: "";
    background-color: currentcolor;
    transition: bottom 0.2s ease-in-out;
  }
}
@media (min-width: 1280px) and (prefers-reduced-motion: reduce) {
  .navbar-expand-xl .navbar-nav .nav-link.active::before {
    transition: none;
  }
}
@media (min-width: 1280px) {
  .navbar-expand-xl .navbar-nav .nav-item {
    border: 0;
  }
  .navbar-expand-xl .navbar-nav .nav-item:first-child > .nav-link {
    margin-left: calc(-1 * var(--bs-navbar-nav-link-padding-x)); /* stylelint-disable-line function-disallowed-list */
  }
  .navbar-expand-xl .navbar-nav .nav-item:last-child > .nav-link {
    margin-right: calc(-1 * var(--bs-navbar-nav-link-padding-x)); /* stylelint-disable-line function-disallowed-list */
  }
  .navbar-expand-xl .navbar-nav .nav-item:first-child > .nav-icon {
    margin-left: calc(-1 * var(--bs-navbar-nav-icon-padding-x)); /* stylelint-disable-line function-disallowed-list */
  }
  .navbar-expand-xl .navbar-nav .nav-item:last-child > .nav-icon {
    margin-right: calc(-1 * var(--bs-navbar-nav-icon-padding-x)); /* stylelint-disable-line function-disallowed-list */
  }
}
@media (min-width: 1280px) {
  .navbar-expand-xl .navbar-collapse.show[class*=d-],
.navbar-expand-xl .navbar-collapse.collapsing[class*=d-] {
    margin-bottom: 0;
    border-top: 0;
  }
  .navbar-expand-xl .navbar-collapse.show:first-of-type,
.navbar-expand-xl .navbar-collapse.collapsing:first-of-type {
    margin-top: 0;
  }
}
@media (min-width: 1280px) {
  .navbar-expand-xl.supra {
    display: flex;
  }
}
@media (min-width: 1280px) {
  .navbar-expand-xl.header-minimized {
    --bs-navbar-nav-link-padding-y: .75rem;
  }
}
@media (min-width: 1280px) {
  .navbar-expand-xl:not(.supra) + .navbar {
    margin-top: 0;
  }
  .navbar-expand-xl:not(.supra) + .navbar .nav-link {
    padding-bottom: calc(var(--bs-navbar-nav-link-padding-y) + 0.0625rem);
  }
  .navbar-expand-xl:not(.supra) + .navbar .nav-link.active::before {
    bottom: 0;
  }
}
@media (min-width: 1280px) {
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
}
@media (min-width: 1280px) {
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
}
@media (min-width: 1280px) {
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
@media (min-width: 1280px) {
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1440px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    margin-left: 0;
  }
  .navbar-expand-xxl .navbar-nav .nav-link.active::before {
    position: absolute;
    right: var(--bs-navbar-nav-link-padding-x);
    bottom: calc(-1 * var(--bs-navbar-padding-y));
    left: var(--bs-navbar-nav-link-padding-x);
    height: calc(var(--bs-navbar-border-width) * 4);
    content: "";
    background-color: currentcolor;
    transition: bottom 0.2s ease-in-out;
  }
}
@media (min-width: 1440px) and (prefers-reduced-motion: reduce) {
  .navbar-expand-xxl .navbar-nav .nav-link.active::before {
    transition: none;
  }
}
@media (min-width: 1440px) {
  .navbar-expand-xxl .navbar-nav .nav-item {
    border: 0;
  }
  .navbar-expand-xxl .navbar-nav .nav-item:first-child > .nav-link {
    margin-left: calc(-1 * var(--bs-navbar-nav-link-padding-x)); /* stylelint-disable-line function-disallowed-list */
  }
  .navbar-expand-xxl .navbar-nav .nav-item:last-child > .nav-link {
    margin-right: calc(-1 * var(--bs-navbar-nav-link-padding-x)); /* stylelint-disable-line function-disallowed-list */
  }
  .navbar-expand-xxl .navbar-nav .nav-item:first-child > .nav-icon {
    margin-left: calc(-1 * var(--bs-navbar-nav-icon-padding-x)); /* stylelint-disable-line function-disallowed-list */
  }
  .navbar-expand-xxl .navbar-nav .nav-item:last-child > .nav-icon {
    margin-right: calc(-1 * var(--bs-navbar-nav-icon-padding-x)); /* stylelint-disable-line function-disallowed-list */
  }
}
@media (min-width: 1440px) {
  .navbar-expand-xxl .navbar-collapse.show[class*=d-],
.navbar-expand-xxl .navbar-collapse.collapsing[class*=d-] {
    margin-bottom: 0;
    border-top: 0;
  }
  .navbar-expand-xxl .navbar-collapse.show:first-of-type,
.navbar-expand-xxl .navbar-collapse.collapsing:first-of-type {
    margin-top: 0;
  }
}
@media (min-width: 1440px) {
  .navbar-expand-xxl.supra {
    display: flex;
  }
}
@media (min-width: 1440px) {
  .navbar-expand-xxl.header-minimized {
    --bs-navbar-nav-link-padding-y: .75rem;
  }
}
@media (min-width: 1440px) {
  .navbar-expand-xxl:not(.supra) + .navbar {
    margin-top: 0;
  }
  .navbar-expand-xxl:not(.supra) + .navbar .nav-link {
    padding-bottom: calc(var(--bs-navbar-nav-link-padding-y) + 0.0625rem);
  }
  .navbar-expand-xxl:not(.supra) + .navbar .nav-link.active::before {
    bottom: 0;
  }
}
@media (min-width: 1440px) {
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
}
@media (min-width: 1440px) {
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
}
@media (min-width: 1440px) {
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}
@media (min-width: 1440px) {
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  margin-left: 0;
}
.navbar-expand .navbar-nav .nav-link.active::before {
  position: absolute;
  right: var(--bs-navbar-nav-link-padding-x);
  bottom: calc(-1 * var(--bs-navbar-padding-y));
  left: var(--bs-navbar-nav-link-padding-x);
  height: calc(var(--bs-navbar-border-width) * 4);
  content: "";
  background-color: currentcolor;
  transition: bottom 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-expand .navbar-nav .nav-link.active::before {
    transition: none;
  }
}
.navbar-expand .navbar-nav .nav-item {
  border: 0;
}
.navbar-expand .navbar-nav .nav-item:first-child > .nav-link {
  margin-left: calc(-1 * var(--bs-navbar-nav-link-padding-x)); /* stylelint-disable-line function-disallowed-list */
}
.navbar-expand .navbar-nav .nav-item:last-child > .nav-link {
  margin-right: calc(-1 * var(--bs-navbar-nav-link-padding-x)); /* stylelint-disable-line function-disallowed-list */
}
.navbar-expand .navbar-nav .nav-item:first-child > .nav-icon {
  margin-left: calc(-1 * var(--bs-navbar-nav-icon-padding-x)); /* stylelint-disable-line function-disallowed-list */
}
.navbar-expand .navbar-nav .nav-item:last-child > .nav-icon {
  margin-right: calc(-1 * var(--bs-navbar-nav-icon-padding-x)); /* stylelint-disable-line function-disallowed-list */
}
.navbar-expand .navbar-collapse.show[class*=d-],
.navbar-expand .navbar-collapse.collapsing[class*=d-] {
  margin-bottom: 0;
  border-top: 0;
}
.navbar-expand .navbar-collapse.show:first-of-type,
.navbar-expand .navbar-collapse.collapsing:first-of-type {
  margin-top: 0;
}
.navbar-expand.supra {
  display: flex;
}
.navbar-expand.header-minimized {
  --bs-navbar-nav-link-padding-y: .75rem;
}
.navbar-expand:not(.supra) + .navbar {
  margin-top: 0;
}
.navbar-expand:not(.supra) + .navbar .nav-link {
  padding-bottom: calc(var(--bs-navbar-nav-link-padding-y) + 0.0625rem);
}
.navbar-expand:not(.supra) + .navbar .nav-link.active::before {
  bottom: 0;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark {
  --bs-navbar-border-color: #666;
  --bs-navbar-color: #fff;
  --bs-navbar-hover-color: #ff7900;
  --bs-navbar-disabled-color: #ddd;
  --bs-navbar-active-color: #ff7900;
  --bs-navbar-brand-color: inherit;
  --bs-navbar-brand-hover-color: #ff7900;
  --bs-navbar-toggler-border-color: transparent;
  --bs-navbar-toggler-icon-filter: invert(1);
}

.card {
  --bs-card-spacer-top: 0.9375rem;
  --bs-card-spacer-bottom: 1.25rem;
  --bs-card-spacer-x: 1.25rem;
  --bs-card-title-spacer-y: 0.625rem;
  --bs-card-border-width: 0.125rem;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: ;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: -0.125rem;
  --bs-card-cap-padding-y: 0.625rem;
  --bs-card-cap-padding-x: 1.25rem;
  --bs-card-cap-bg: #000;
  --bs-card-cap-color: #fff;
  --bs-card-cap-font-weight: 700;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1.25rem;
  --bs-card-group-margin: 0.625rem;
  --bs-card-footer-color: #666;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
}
.card > .list-group:last-child {
  border-bottom-width: 0;
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-top) var(--bs-card-spacer-x) var(--bs-card-spacer-bottom);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}
.card-title:only-child {
  margin-bottom: 0;
}

.card-subtitle {
  margin-top: 0;
  margin-bottom: var(--bs-card-title-spacer-y);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  font-weight: var(--bs-card-cap-font-weight);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-cap-bg);
}
.card-footer {
  padding: 0 var(--bs-card-cap-padding-x) var(--bs-card-cap-padding-x);
  font-weight: var(--bs-card-cap-font-weight);
  color: var(--bs-card-footer-color);
  background-color: var(--bs-card-bg);
}
.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 480px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
}

.accordion {
  --bs-accordion-color: #000;
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: border-radius 0.15s ease;
  --bs-accordion-border-color: #ccc;
  --bs-accordion-border-width: 0.0625rem;
  --bs-accordion-border-radius: ;
  --bs-accordion-inner-border-radius: -0.0625rem;
  --bs-accordion-btn-padding-x: 0;
  --bs-accordion-btn-padding-y: 0.625rem;
  --bs-accordion-btn-font-size: 1.5rem;
  --bs-accordion-btn-font-weight: 700;
  --bs-accordion-btn-line-height: ;
  --bs-accordion-btn-letter-spacing: -0.03125rem;
  --bs-accordion-btn-color: #000;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon-transform: scaleY(-1);
  --bs-accordion-body-padding-top: 5px;
  --bs-accordion-body-padding-end: 1.25rem;
  --bs-accordion-body-padding-bottom: 1.25rem;
  --bs-accordion-body-padding-start: 0;
  --bs-accordion-active-color: #f16e00;
  --bs-accordion-active-bg: ;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: var(--bs-accordion-btn-font-size);
  font-weight: var(--bs-accordion-btn-font-weight);
  line-height: var(--bs-accordion-btn-line-height);
  color: var(--bs-accordion-btn-color);
  text-align: left;
  text-decoration: none;
  /* rtl:remove */
  letter-spacing: var(--bs-accordion-btn-letter-spacing);
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
}
.accordion-button:not(.collapsed)::after {
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  display: inline-block;
  align-self: center;
  margin: 0 0.625rem 0 auto;
  content: "";
  border-top: 0.4375rem solid;
  border-right: 0.4375rem solid transparent;
  border-bottom: 0;
  border-left: 0.4375rem solid transparent;
  transform: translateY(0.0625rem);
}
.accordion-button:empty::after {
  margin-left: 0;
}
.accordion-button:hover {
  z-index: 2;
  color: var(--bs-accordion-active-color);
}
.accordion-button:focus[data-focus-visible-added] {
  outline-offset: calc(2px + var(--bs-accordion-border-width));
  box-shadow: 0 0 0 calc(var(--bs-accordion-border-width) + 2px) var(--bs-focus-visible-inner-color);
}

.accordion-header {
  margin-bottom: 0;
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
  border-width: var(--bs-accordion-border-width) 0 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
}
.accordion-item:last-of-type {
  margin-bottom: 0;
  border-bottom: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-body {
  padding: var(--bs-accordion-body-padding-top) var(--bs-accordion-body-padding-end) var(--bs-accordion-body-padding-bottom) var(--bs-accordion-body-padding-start);
}

.accordion-sm {
  --bs-accordion-btn-font-size: 1.125rem;
  --bs-accordion-btn-line-height: 1.1111111111;
  --bs-accordion-btn-letter-spacing: -0.0125rem;
}

.accordion-lg {
  --bs-accordion-btn-font-size: 1.875rem;
  --bs-accordion-btn-line-height: 1.3333333333;
  --bs-accordion-btn-letter-spacing: -0.05rem;
}

.accordion-dark {
  --bs-accordion-btn-color: #fff;
  --bs-accordion-btn-bg: #000;
  --bs-accordion-active-color: #ff7900;
  --bs-accordion-border-color: #666;
  --bs-accordion-color: #fff;
  --bs-accordion-bg: #000;
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0.5rem;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-font-size: 0.875rem;
  --bs-breadcrumb-font-weight: 700;
  --bs-breadcrumb-color: #000;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: ;
  --bs-breadcrumb-item-padding-x: 0.625rem;
  --bs-breadcrumb-item-active-color: ;
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  font-weight: var(--bs-breadcrumb-font-weight);
  color: var(--bs-breadcrumb-color);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x) /* rtl:ignore */;
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 14' width='7' height='10'%3e%3cpath d='m-.4 12 2 2 7-7-7-7-2 2 5 5z'/%3e%3c/svg%3e")) /* rtl: var(--bs-breadcrumb-divider, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 14' width='7' height='10'%3e%3cpath d='m-.4 12 2 2 7-7-7-7-2 2 5 5z'/%3e%3c/svg%3e")) */;
  /* rtl:raw:
  transform: scaleX(-1);
  */
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.breadcrumb-dark {
  --bs-breadcrumb-color: #fff;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-divider-color: #000;
  --bs-breadcrumb-item-active-color: ;
}
.breadcrumb-dark .breadcrumb-item::before {
  filter: invert(1);
}

.pagination {
  --bs-pagination-padding-x: ;
  --bs-pagination-padding-y: ;
  --bs-pagination-padding-end: 1.125rem;
  --bs-pagination-margin-y: 1.25rem;
  --bs-pagination-margin-x-first-last: 0.625rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-font-weight: 700;
  --bs-pagination-color: ;
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 0.125rem;
  --bs-pagination-border-color: transparent;
  --bs-pagination-border-radius: ;
  --bs-pagination-hover-color: var(--bs-link-color);
  --bs-pagination-hover-bg: ;
  --bs-pagination-hover-border-color: #ccc;
  --bs-pagination-focus-color: ;
  --bs-pagination-focus-bg: ;
  --bs-pagination-focus-box-shadow: 0 0 0 2px var(--bs-focus-visible-inner-color);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #000;
  --bs-pagination-active-border-color: #000;
  --bs-pagination-active-item-color: #000;
  --bs-pagination-active-item-bg: #ff7900;
  --bs-pagination-active-item-border-color: #ff7900;
  --bs-pagination-disabled-color: #ccc;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #ccc;
  --bs-pagination-icon: var(--o-chevron-icon);
  --bs-pagination-icon-size: 2.25rem;
  --bs-pagination-icon-width: calc(0.5rem + 1px);
  --bs-pagination-icon-height: calc(1rem - 1px);
  display: flex;
  flex-wrap: wrap;
  margin: var(--bs-pagination-margin-y) 0;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  box-sizing: content-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  font-weight: var(--bs-pagination-font-weight);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  min-width: var(--bs-pagination-icon-size);
  min-height: var(--bs-pagination-icon-size);
}
.page-item:first-child .page-link::before, .page-item:last-child .page-link::before {
  display: block;
  min-width: inherit;
  min-height: inherit;
  content: "";
  background-color: currentcolor;
  -webkit-mask: var(--bs-pagination-icon) no-repeat calc(50% - 1px)/var(--bs-pagination-icon-width) var(--bs-pagination-icon-height);
  mask: var(--bs-pagination-icon) no-repeat calc(50% - 1px)/var(--bs-pagination-icon-width) var(--bs-pagination-icon-height);
}

.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 5;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link:active, .page-link:active > .page-link {
  color: var(--bs-pagination-active-item-color);
  background-color: var(--bs-pagination-active-item-bg);
  border-color: var(--bs-pagination-active-item-border-color);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: 0;
}
.page-item:first-child:not(.active) .page-link, .page-item:last-child:not(.active) .page-link {
  border-color: currentcolor;
}
.page-item:first-child:not(.active) .page-link:hover, .page-item:last-child:not(.active) .page-link:hover {
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-item:first-child:not(.active) .page-link.active, .page-item:last-child:not(.active) .page-link.active {
  border-color: var(--bs-pagination-border-color);
}
.page-item:first-child:not(.active) .page-link:active, .page-item:last-child:not(.active) .page-link:active {
  color: var(--bs-pagination-active-item-color);
  background-color: var(--bs-pagination-active-item-bg);
  border-color: var(--bs-pagination-active-item-border-color);
}
.page-item:first-child .page-link {
  margin-right: var(--bs-pagination-margin-x-first-last);
}
.page-item:first-child .page-link:not(:empty) {
  padding-right: var(--bs-pagination-padding-end);
}
.page-item:first-child .page-link::before {
  /* rtl:raw:
  transform: scaleX(-1);
  */
}
.page-item:last-child .page-link:not(:empty) {
  padding-left: var(--bs-pagination-padding-end);
}
.page-item:last-child .page-link::before {
  order: 1;
  /* rtl:remove */
  transform: scaleX(-1);
}
.page-item:last-child:not(:nth-child(2)) .page-link {
  margin-left: var(--bs-pagination-margin-x-first-last);
}
@media (max-width: 479.98px) {
  .page-item {
    display: none;
  }
  .page-item.active, .page-item:nth-child(-n+2), .page-item:nth-last-child(-n+2) {
    display: list-item;
  }
}
@media (min-width: 480px) and (max-width: 767.98px) {
  .page-item {
    display: none;
  }
  .page-item.active, .page-item:nth-child(-n+3), .page-item:nth-last-child(-n+3) {
    display: list-item;
  }
}
@media (min-width: 768px) and (max-width: 1023.98px) {
  .page-item {
    display: none;
  }
  .page-item.active, .page-item:nth-child(-n+4), .page-item:nth-last-child(-n+4) {
    display: list-item;
  }
}
@media (min-width: 1024px) and (max-width: 1279.98px) {
  .page-item {
    display: none;
  }
  .page-item.active, .page-item:nth-child(-n+5), .page-item:nth-last-child(-n+5) {
    display: list-item;
  }
}
@media (min-width: 1280px) {
  .page-item {
    display: none;
  }
  .page-item.active, .page-item:nth-child(-n+6), .page-item:nth-last-child(-n+6) {
    display: list-item;
  }
}

.pagination-dark {
  --bs-pagination-color: #fff;
  --bs-pagination-bg: #000;
  --bs-pagination-border-color: transparent;
  --bs-pagination-hover-color: #fff;
  --bs-pagination-hover-bg: #000;
  --bs-pagination-hover-border-color: #666;
  --bs-pagination-focus-color: #fff;
  --bs-pagination-focus-bg: #000;
  --bs-pagination-active-color: #000;
  --bs-pagination-active-bg: #fff;
  --bs-pagination-active-border-color: #fff;
  --bs-pagination-active-item-color: #000;
  --bs-pagination-active-item-bg: #ff7900;
  --bs-pagination-active-item-border-color: #ff7900;
  --bs-pagination-disabled-color: #666;
  --bs-pagination-disabled-bg: #000;
  --bs-pagination-disabled-border-color: #666;
}

.badge {
  --bs-badge-padding: calc(var(--bs-badge-padding-y) - 1px) var(--bs-badge-padding-x) calc(var(--bs-badge-padding-y) + 1px);
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: ;
  display: inline-block;
  padding: var(--bs-badge-padding);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  /* rtl:remove */
  letter-spacing: initial;
  white-space: nowrap;
  vertical-align: baseline;
}
.badge:empty {
  display: none;
}

.btn .badge {
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  margin-left: 0.3125rem;
}

.badge.bg-primary {
  color: #000;
  background-color: #ff7900 !important;
}

.badge.bg-secondary {
  color: #fff;
  background-color: #000 !important;
}

.badge.bg-success {
  color: #000;
  background-color: #32c832 !important;
}

.badge.bg-info {
  color: #000;
  background-color: #527edb !important;
}

.badge.bg-warning {
  color: #000;
  background-color: #fc0 !important;
}

.badge.bg-danger {
  color: #fff;
  background-color: #cd3c14 !important;
}

.badge.bg-light {
  color: #000;
  background-color: #ccc !important;
}

.badge.bg-dark {
  color: #fff;
  background-color: #000 !important;
}

.tag {
  --bs-tag-gap: 0.3125rem;
  --bs-tag-padding-x: 0.8125rem;
  --bs-tag-padding-y: 0.5625rem;
  --bs-tag-font-size: 1rem;
  --bs-tag-font-shift: 0.125rem;
  --bs-tag-font-weight: 700;
  --bs-tag-icon-size: 1.5rem;
  --bs-tag-icon-margin-start: -0.4375rem;
  --bs-tag-close-size: calc(var(--bs-tag-font-size) + 0.375rem);
  --bs-tag-close-margin-end: -0.375rem;
  --bs-tag-close-margin-start: 0.25rem;
  --bs-tag-border-width: 0.125rem;
  --bs-tag-border-radius: 50rem;
  --bs-tag-border-color: #ccc;
  --bs-tag-active-color: #fff;
  --bs-tag-active-decoration-color: #000;
  --bs-tag-disabled-color: #ccc;
  display: inline-flex;
  gap: var(--bs-tag-gap);
  align-items: center;
  padding: calc(var(--bs-tag-padding-y) - (calc(var(--bs-tag-font-shift) * 0.5))) var(--bs-tag-padding-x) calc(var(--bs-tag-padding-y) + calc(var(--bs-tag-font-shift) * 0.5));
  font-size: var(--bs-tag-font-size);
  font-weight: var(--bs-tag-font-weight);
  line-height: calc(var(--bs-tag-font-size) + 0.125rem);
  color: inherit;
  background-color: transparent;
  border: var(--bs-tag-border-width) solid var(--bs-tag-border-color);
  border-radius: var(--bs-tag-border-radius);
}
.tag img,
.tag svg {
  width: var(--bs-tag-icon-size);
  min-width: var(--bs-tag-icon-size);
  height: var(--bs-tag-icon-size);
  margin: calc(calc(-0.5 * var(--bs-tag-icon-size)) + var(--bs-tag-font-shift)) 0 calc(-0.5 * var(--bs-tag-icon-size)) var(--bs-tag-icon-margin-start);
}
.tag .close {
  position: relative;
  width: var(--bs-tag-close-size);
  min-width: var(--bs-tag-close-size);
  height: var(--bs-tag-close-size);
  padding: 0;
  margin: calc(calc(-0.5 * var(--bs-tag-close-size)) + var(--bs-tag-font-shift)) var(--bs-tag-close-margin-end) calc(-0.5 * var(--bs-tag-close-size)) var(--bs-tag-close-margin-start);
  color: inherit;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-tag-border-radius);
}
.tag .close::before {
  position: absolute;
  top: calc(-1 * var(--bs-tag-padding-y));
  right: calc(-1 * var(--bs-tag-padding-y));
  bottom: calc(-1 * var(--bs-tag-padding-y));
  left: calc(-1 * var(--bs-tag-padding-y));
  content: "";
  border-radius: var(--bs-tag-border-radius);
}
.tag .close::after {
  display: block;
  min-width: calc(var(--bs-tag-close-size) - 0.625rem);
  min-height: calc(var(--bs-tag-close-size) - 0.625rem);
  content: "";
  background-color: currentcolor;
  -webkit-mask: var(--o-close-icon) no-repeat 50%/calc(var(--bs-tag-close-size) - 0.625rem);
  mask: var(--o-close-icon) no-repeat 50%/calc(var(--bs-tag-close-size) - 0.625rem);
}
.tag .close:hover, .tag .close:active {
  color: var(--bs-tag-active-color);
  background-color: var(--bs-tag-active-decoration-color);
}
.tag .close[disabled] {
  pointer-events: none;
}
.tag[disabled], .tag.disabled {
  color: var(--bs-tag-disabled-color);
  pointer-events: none;
  border-color: var(--bs-tag-disabled-color);
}

a.tag,
button.tag,
label.tag {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
a.tag:hover,
button.tag:hover,
label.tag:hover {
  border-color: var(--bs-tag-active-decoration-color);
}
a.tag.active, a.tag:active,
button.tag.active,
button.tag:active,
label.tag.active,
label.tag:active {
  color: var(--bs-tag-active-color);
  background-color: var(--bs-tag-active-decoration-color);
  border-color: var(--bs-tag-active-decoration-color);
}
a.tag.active[disabled], a.tag.active.disabled, a.tag:active[disabled], a.tag:active.disabled,
button.tag.active[disabled],
button.tag.active.disabled,
button.tag:active[disabled],
button.tag:active.disabled,
label.tag.active[disabled],
label.tag.active.disabled,
label.tag:active[disabled],
label.tag:active.disabled {
  background-color: var(--bs-tag-disabled-color);
  border-color: var(--bs-tag-disabled-color);
}

.tag-sm {
  --bs-tag-padding-x: 0.5rem;
  --bs-tag-padding-y: 0.3125rem;
  --bs-tag-font-size: 0.875rem;
  --bs-tag-icon-size: 1.25rem;
  --bs-tag-icon-margin-start: -0.125rem;
  --bs-tag-close-margin-end: -0.3125rem;
  --bs-tag-close-margin-start: 0;
}

.tag-dark {
  --bs-tag-border-color: #666;
  --bs-tag-active-color: #000;
  --bs-tag-active-decoration-color: #fff;
  --bs-tag-disabled-color: #666;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1.25rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1.25rem;
  --bs-alert-font-size: 1rem;
  --bs-alert-line-height: 1.125;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 0.125rem solid var(--bs-alert-border-color);
  --bs-alert-border-radius: ;
  --bs-alert-logo-size: 1.625rem;
  --bs-alert-icon-size: 3rem;
  --bs-alert-icon-margin-y: 0.125rem;
  --bs-alert-link-font-weight: ;
  --bs-alert-heading-font-weight: 700;
  --bs-alert-dismissible-padding-right: 3rem;
  --bs-alert-btn-close-offset: 0.5rem;
  position: relative;
  display: flex;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  font-size: var(--bs-alert-font-size);
  line-height: var(--bs-alert-line-height);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
}

.alert-heading {
  margin: 0;
  font-size: inherit;
  font-weight: var(--bs-alert-heading-font-weight);
  line-height: inherit;
  color: inherit;
}

.alert-link {
  font-weight: var(--bs-alert-link-font-weight);
}

.alert-dismissible {
  padding-right: var(--bs-alert-dismissible-padding-right);
}
.alert-dismissible .btn-close {
  position: absolute;
  top: var(--bs-alert-btn-close-offset);
  right: var(--bs-alert-btn-close-offset);
  z-index: 2;
}

.alert-success {
  --bs-alert-color: inherit;
  --bs-alert-bg: transparent;
  --bs-alert-border: 0.125rem solid #32c832;
}
.alert-success .alert-icon::before {
  background-image: var(--o-success-icon);
}

.alert-info {
  --bs-alert-color: inherit;
  --bs-alert-bg: transparent;
  --bs-alert-border: 0.125rem solid #527edb;
}
.alert-info .alert-icon::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 125 125'%3e%3cpath fill='%23527edb' d='M62.5 0a62.5 62.5 0 1 0 0 125 62.5 62.5 0 0 0 0-125zm0 14.7a11 11 0 1 1 0 22 11 11 0 0 1 0-22zM47.8 44.1h25.7v46.2c0 4.7 1.3 6.5 1.8 7.2.8 1 2.3 1.5 4.8 1.6h.8v3.8H47.8v-3.7h.8c2.3-.1 4-.8 5-2 .4-.4 1-2 1-7V57c0-4.8-.6-6.6-1.2-7.3-.8-1-2.4-1.5-4.9-1.6h-.7V44z'/%3e%3c/svg%3e");
}

.alert-warning {
  --bs-alert-color: inherit;
  --bs-alert-bg: transparent;
  --bs-alert-border: 0.125rem solid #fc0;
}
.alert-warning .alert-icon::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath fill='%23fc0' d='M15 0a15 15 0 1 0 0 30 15 15 0 0 0 0-30zm.15 5.39h.01c1.12 0 2 .95 1.92 2.06l-.63 10.43c0 .7-.58.97-1.29.97-.72 0-1.28-.27-1.28-.97l-.63-10.46c-.06-1.09.8-2.01 1.9-2.03zm-.3 15.33c.11 0 .21 0 .31.02 2.19.35 2.19 3.5 0 3.84-2.77.44-3.1-3.86-.3-3.86z'/%3e%3c/svg%3e");
}

.alert-danger {
  --bs-alert-color: inherit;
  --bs-alert-bg: transparent;
  --bs-alert-border: 0.125rem solid #cd3c14;
}
.alert-danger .alert-icon::before {
  background-image: var(--o-error-icon);
}

.alert * p {
  margin: 0;
}

.alert-heading ~ p {
  line-height: 1.5;
}

.alert-icon {
  flex-shrink: 0;
  width: var(--bs-alert-icon-size);
  margin: calc(-0.5 * var(--bs-alert-icon-margin-y)) 0;
}
.alert-icon + * {
  margin: var(--bs-alert-icon-margin-y) 0 0;
}
.alert-icon + p {
  font-weight: var(--bs-alert-heading-font-weight);
}
.alert-icon::before {
  display: block;
  order: -1;
  height: var(--bs-alert-logo-size);
  content: "";
  background-repeat: no-repeat;
  /* rtl:raw:
  background-position: top right;
  */
  background-size: var(--bs-alert-logo-size);
}

.alert-sm {
  --bs-alert-padding-x: 0;
  --bs-alert-padding-y: 0.625rem;
  --bs-alert-margin-bottom: 0;
  --bs-alert-font-size: 0.875rem;
  --bs-alert-line-height: 1.1428571429;
  --bs-alert-border: 0;
  --bs-alert-logo-size: calc(1rem + 1px);
  --bs-alert-icon-size: 1.5rem;
  --bs-alert-btn-close-offset: 0.3125rem;
  --o-icon-spacing: 0.1875rem;
}
.alert-sm .alert-icon {
  margin: var(--bs-alert-icon-margin-y) 0;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1.25rem;
  }
}
.progress {
  --bs-progress-height: 1.25rem;
  --bs-progress-font-size: 1rem;
  --bs-progress-bg: #eee;
  --bs-progress-border-radius: ;
  --bs-progress-box-shadow: ;
  --bs-progress-bar-color: #000;
  --bs-progress-bar-font-weight: 700;
  --bs-progress-bar-text-indent: 0.625rem;
  --bs-progress-bar-bg: #ff7900;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
}

.progress-xs {
  --bs-progress-height: 0.3125rem;
}

.progress-sm {
  --bs-progress-height: 0.625rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  font-weight: var(--bs-progress-bar-font-weight);
  color: var(--bs-progress-bar-color);
  text-indent: var(--bs-progress-bar-text-indent);
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: #000;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: #ccc;
  --bs-list-group-border-width: 0.125rem;
  --bs-list-group-border-radius: ;
  --bs-list-group-item-padding-x: 1.25rem;
  --bs-list-group-item-padding-y: 0.875rem;
  --bs-list-group-action-color: #000;
  --bs-list-group-action-hover-color: #ff7900;
  --bs-list-group-action-active-color: #fff;
  --bs-list-group-action-active-bg: #000;
  --bs-list-group-disabled-color: #ccc;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #000;
  --bs-list-group-active-border-color: #000;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}
.list-group-item:focus[data-focus-visible-added] {
  outline-offset: calc(-3px - (var(--bs-list-group-border-width)));
  box-shadow: inset 0 0 0 5px var(--bs-focus-visible-inner-color);
}
.list-group-item:focus[data-focus-visible-added].active {
  outline-color: var(--bs-focus-visible-inner-color);
  box-shadow: inset 0 0 0 5px var(--bs-focus-visible-outer-color);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 480px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1024px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1280px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1440px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #000;
  background-color: #ff7900;
}
.list-group-item-primary.list-group-item-action:hover {
  color: #000;
  background-color: #ff861a;
}

.list-group-item-secondary {
  color: #fff;
  background-color: #000;
}
.list-group-item-secondary.list-group-item-action:hover {
  color: #fff;
  background-color: black;
}

.list-group-item-success {
  color: #000;
  background-color: #50be87;
}
.list-group-item-success.list-group-item-action:hover {
  color: #000;
  background-color: #62c593;
}

.list-group-item-info {
  color: #000;
  background-color: #4bb4e6;
}
.list-group-item-info.list-group-item-action:hover {
  color: #000;
  background-color: #5dbce9;
}

.list-group-item-warning {
  color: #000;
  background-color: #ffd200;
}
.list-group-item-warning.list-group-item-action:hover {
  color: #000;
  background-color: #ffd71a;
}

.list-group-item-danger {
  color: #fff;
  background-color: #cd3c14;
}
.list-group-item-danger.list-group-item-action:hover {
  color: #fff;
  background-color: #b93612;
}

.list-group-item-light {
  color: #000;
  background-color: #ccc;
}
.list-group-item-light.list-group-item-action:hover {
  color: #000;
  background-color: #d1d1d1;
}

.list-group-item-dark {
  color: #fff;
  background-color: #000;
}
.list-group-item-dark.list-group-item-action:hover {
  color: #fff;
  background-color: black;
}

.list-group-dark {
  --bs-list-group-color: #fff;
  --bs-list-group-bg: #000;
  --bs-list-group-border-color: #666;
  --bs-list-group-action-color: #fff;
  --bs-list-group-action-hover-color: #ff7900;
  --bs-list-group-action-active-color: #000;
  --bs-list-group-action-active-bg: #fff;
  --bs-list-group-disabled-color: #666;
  --bs-list-group-disabled-bg: #000;
  --bs-list-group-active-color: #000;
  --bs-list-group-active-bg: #fff;
  --bs-list-group-active-border-color: #fff;
}

.btn-close {
  --bs-btn-close-padding: var(--o-icon-spacing, 0.5rem);
  --bs-btn-close-border-width: 0.125rem;
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: var(--o-close-icon);
  --bs-btn-close-border-color: transparent;
  --bs-btn-close-hover-color: #000;
  --bs-btn-close-active-color: #ff7900;
  --bs-btn-close-active-border-color: #ccc;
  --bs-btn-close-disabled-color: #ccc;
  padding: var(--bs-btn-close-padding);
  color: var(--bs-btn-close-color);
  background-color: var(--bs-btn-close-bg);
  border: var(--bs-btn-close-border-width) solid var(--bs-btn-close-border-color);
  min-width: 1.25rem;
  min-height: 1.25rem;
}
.btn-close::before {
  display: block;
  min-width: inherit;
  min-height: inherit;
  content: "";
  background-color: currentcolor;
  -webkit-mask: var(--o-close-icon) no-repeat 50%/1rem auto;
  mask: var(--o-close-icon) no-repeat 50%/1rem auto;
}

.btn-close:hover {
  color: var(--bs-btn-close-hover-color);
  text-decoration: none;
}
.btn-close:hover, .btn-close:active {
  border: var(--bs-btn-close-border-width) solid var(--bs-btn-close-active-border-color);
}
.btn-close:active {
  color: var(--bs-btn-close-active-color);
}
.btn-close:disabled, .btn-close.disabled {
  color: var(--bs-btn-close-disabled-color);
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.btn-close-white {
  --bs-btn-close-color: #fff;
  --bs-btn-close-bg: transparent;
  --bs-btn-close-border-color: transparent;
  --bs-btn-close-hover-color: #fff;
  --bs-btn-close-active-color: #ff7900;
  --bs-btn-close-active-border-color: #666;
  --bs-btn-close-disabled-color: #666;
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.625rem;
  --bs-toast-padding-y: 0.3125rem;
  --bs-toast-spacing: 1.25rem;
  --bs-toast-max-width: 21.875rem;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: #000;
  --bs-toast-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-border-width: 0.125rem;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: ;
  --bs-toast-box-shadow: ;
  --bs-toast-header-color: ;
  --bs-toast-header-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-header-border-color: rgba(0, 0, 0, 0.05);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
}
.toast-header .btn-close {
  --o-icon-spacing: 0.1875rem;
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 460px;
  --bs-modal-padding: 0.625rem 1.25rem;
  --bs-modal-margin: 0.625rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 0.125rem;
  --bs-modal-border-radius: ;
  --bs-modal-box-shadow: ;
  --bs-modal-inner-border-radius: ;
  --bs-modal-header-padding: 0 1.25rem;
  --bs-modal-header-border-color: ;
  --bs-modal-header-border-width: 0.125rem;
  --bs-modal-title-line-height: 1.125;
  --bs-modal-footer-gap: 0.625rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: ;
  --bs-modal-footer-border-width: 0.125rem;
  --bs-modal-footer-padding: 0.625rem 0.9375rem 0;
  --bs-modal-content-padding: 1.25rem 0;
  --bs-modal-img-margin: -1.25rem 0 1.25rem;
  --bs-modal-img-btn-close-offset: 1.25rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  --bs-modal-scrollable-inner-padding: 1.25rem;
  --bs-modal-scrollable-inner-margin: 1.25rem 0 0.625rem;
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  padding: var(--bs-modal-scrollable-inner-padding);
  margin: var(--bs-modal-scrollable-inner-margin);
  overflow-y: auto;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-width: var(--bs-modal-border-width) 0;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-dialog .modal-body > :last-child {
  margin-bottom: 0;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: var(--bs-modal-content-padding);
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-img {
  margin: var(--bs-modal-img-margin);
}
.modal-img + .modal-header .btn-close {
  position: absolute;
  top: var(--bs-modal-img-btn-close-offset);
  right: var(--bs-modal-img-btn-close-offset);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
}
.modal-header .btn-close {
  margin: calc(var(--o-icon-spacing, 0.5rem) * -1) calc(var(--o-icon-spacing, 0.5rem) * -1) calc(var(--o-icon-spacing, 0.5rem) * -1) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: var(--bs-modal-footer-padding);
  background-color: var(--bs-modal-footer-bg);
}
.modal-footer > * {
  margin: 0 calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 480px) {
  .modal {
    --bs-modal-margin: 1.875rem;
    --bs-modal-box-shadow: ;
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 1024px) {
  .modal-lg,
.modal-xl {
    --bs-modal-width: 700px;
  }
}
@media (min-width: 1280px) {
  .modal-xl {
    --bs-modal-width: 940px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 479.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1023.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1279.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1439.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 12.5rem;
  --bs-tooltip-padding-x: 0.625rem;
  --bs-tooltip-padding-y: 0.625rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-font-weight: 700;
  --bs-tooltip-color: #000;
  --bs-tooltip-bg: #ddd;
  --bs-tooltip-border-radius: ;
  --bs-tooltip-opacity: 1;
  --bs-tooltip-arrow-width: 0.625rem;
  --bs-tooltip-arrow-height: 0.3125rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.125;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: -0.00625rem;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  font-weight: var(--bs-tooltip-font-weight);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  background-color: var(--bs-tooltip-bg);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 17.25rem;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-font-weight: 700;
  --bs-popover-bg: #ddd;
  --bs-popover-border-width: 0.125rem;
  --bs-popover-border-color: #ddd;
  --bs-popover-border-radius: ;
  --bs-popover-inner-border-radius: -0.125rem;
  --bs-popover-box-shadow: ;
  --bs-popover-header-padding-x: 0.625rem;
  --bs-popover-header-padding-y: 0.625rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: ;
  --bs-popover-header-bg: #ddd;
  --bs-popover-body-padding-x: 0.625rem;
  --bs-popover-body-padding-y: 0.625rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 0.625rem;
  --bs-popover-arrow-height: 0.3125rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.125;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: -0.00625rem;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  font-weight: var(--bs-popover-font-weight);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * var(--bs-popover-arrow-height) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * var(--bs-popover-arrow-height) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * var(--bs-popover-arrow-height) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * var(--bs-popover-arrow-height) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  /* rtl:remove */
  letter-spacing: unset;
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid #c7c7c7;
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  --o-control-bg: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.75rem;
  padding: 0;
  color: #000;
  text-align: center;
  background: none;
  border: 0;
  outline: 0;
  box-shadow: none;
}
.carousel-control-prev:hover,
.carousel-control-next:hover {
  --o-control-bg: #000;
  color: #fff;
  text-decoration: none;
}
.carousel-control-prev:disabled, .carousel-control-prev[aria-disabled],
.carousel-control-next:disabled,
.carousel-control-next[aria-disabled] {
  color: #666;
  pointer-events: none;
}
.carousel-control-prev:active,
.carousel-control-next:active {
  --o-control-bg: #ff7900;
  color: #000;
}
.carousel-control-prev:focus[data-focus-visible-added] > span,
.carousel-control-next:focus[data-focus-visible-added] > span {
  z-index: 5;
  isolation: isolate;
  outline: 3px solid var(--bs-focus-visible-outer-color);
  outline-offset: 2px;
  box-shadow: 0 0 0 2px var(--bs-focus-visible-inner-color);
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  background-color: var(--o-control-bg);
  min-width: 2.5rem;
  min-height: 2.5rem;
  border-radius: 50%;
}
.carousel-control-prev-icon::before, .carousel-control-next-icon::before {
  display: block;
  min-width: inherit;
  min-height: inherit;
  content: "";
  background-color: currentcolor;
  -webkit-mask: var(--o-chevron-icon) no-repeat calc(50% - 0.125rem) 50%/1rem 1.5rem;
  mask: var(--o-chevron-icon) no-repeat calc(50% - 0.125rem) 50%/1rem 1.5rem;
}

/* rtl:begin:remove */
.carousel-control-next-icon {
  transform: scaleX(-1);
}

/* rtl:end:remove */
/* rtl:raw:
.carousel-control-prev-icon {
  transform: scaleX(-1);
}
*/
/* rtl:begin:ignore */
.carousel-indicators {
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0.625rem 0;
  margin-bottom: 1.25rem;
  list-style: none;
  background: rgba(255, 255, 255, 0.5);
  transform: translateX(-50%);
  border-radius: 1.25rem;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 0.5rem;
  height: 0.5rem;
  padding: 0;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  text-indent: -999px;
  cursor: pointer;
  background-color: #000;
  border: 0;
  border-radius: 50%;
  position: relative;
}
.carousel-indicators [data-bs-target]::before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1.875rem;
  min-width: 100%;
  height: 1.875rem;
  min-height: 100%;
  content: "";
  transform: translate3d(-50%, -50%, 0);
}
.carousel-indicators [data-bs-target]:hover, .carousel-indicators [data-bs-target]:focus {
  background-color: #000;
}
.carousel-indicators [data-bs-target]:hover {
  transform: scale(1.5);
}
.carousel-indicators [data-bs-target]:hover::before {
  transform: translate3d(-50%, -50%, 0) scale(0.6666666667);
}
.carousel-indicators [data-bs-target]:focus[data-focus-visible-added] {
  transform: none;
}
.carousel-indicators .active {
  background-color: #fff;
  border-color: #000;
  background-image: linear-gradient(to right, transparent 50%, #000 0);
  transform: scale(1.5);
  -webkit-mask-image: radial-gradient(circle at 50%, transparent 33%, #fff calc(33% + 1px));
  mask-image: radial-gradient(circle at 50%, transparent 33%, #fff calc(33% + 1px));
}
.carousel-indicators .active:hover, .carousel-indicators .active:focus {
  -webkit-mask-image: none;
  mask-image: none;
}
.carousel-indicators .active::before {
  transform: translate3d(-50%, -50%, 0) scale(0.6666666667);
}
.carousel-indicators .active::after {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  content: "";
  background-color: inherit;
  transform-origin: left;
  animation: carousel-progress calc(var(--carousel-interval, 5000ms) / 2) linear infinite, carousel-progress-half var(--carousel-interval, 5000ms) step-end infinite;
  border-radius: 0 100% 100% 0/50%;
}
@keyframes carousel-progress {
  to {
    transform: rotate(0.5turn);
  }
}
@keyframes carousel-progress-half {
  50% {
    background: #000;
  }
}
.carousel.is-done .carousel-indicators .active, .carousel.is-paused .carousel-indicators .active, .carousel.is-static .carousel-indicators .active {
  background: #000;
}
.carousel.is-done .carousel-indicators .active::after, .carousel.is-paused .carousel-indicators .active::after, .carousel.is-static .carousel-indicators .active::after {
  animation: none;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators .active {
    background: #000;
  }
  .carousel-indicators .active::after {
    animation: none;
  }
}

/* rtl:end:ignore */
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 3.75rem;
  left: 15%;
  padding: 1.25rem 1.25rem;
  color: #000;
  background-color: #fff;
}

.carousel-control-play-pause.pause {
  min-width: 1.25rem;
  min-height: 1.25rem;
}
.carousel-control-play-pause.pause::after {
  display: block;
  min-width: inherit;
  min-height: inherit;
  content: "";
  background-color: currentcolor;
  -webkit-mask: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='currentColor' d='M10.2 21H3V3h7.2v18ZM21 21h-7.2V3H21v18Z' fill-rule='evenodd'/></svg>") no-repeat 50%/1.25rem 1.25rem;
  mask: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='currentColor' d='M10.2 21H3V3h7.2v18ZM21 21h-7.2V3H21v18Z' fill-rule='evenodd'/></svg>") no-repeat 50%/1.25rem 1.25rem;
}

.carousel-control-play-pause.play {
  min-width: 1.25rem;
  min-height: 1.25rem;
}
.carousel-control-play-pause.play::after {
  display: block;
  min-width: inherit;
  min-height: inherit;
  content: "";
  background-color: currentcolor;
  -webkit-mask: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='currentColor' d='M12.138 16.8 3 21.6V2.4l9.138 4.8L21 12z' fill-rule='evenodd'/></svg>") no-repeat 50%/1.25rem 1.25rem;
  mask: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='currentColor' d='M12.138 16.8 3 21.6V2.4l9.138 4.8L21 12z' fill-rule='evenodd'/></svg>") no-repeat 50%/1.25rem 1.25rem;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2.5rem;
  --bs-spinner-height: 2.5rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.375rem;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1.25rem;
  --bs-spinner-height: 1.25rem;
  --bs-spinner-border-width: 0.25rem;
}

.spinner-border-lg {
  --bs-spinner-width: 5rem;
  --bs-spinner-height: 5rem;
  --bs-spinner-border-width: 0.5rem;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2.5rem;
  --bs-spinner-height: 2.5rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1.25rem;
  --bs-spinner-height: 1.25rem;
}

.spinner-grow-lg {
  --bs-spinner-width: 5rem;
  --bs-spinner-height: 5rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 0.625rem 1.25rem;
  --bs-offcanvas-padding-y: 0.625rem 1.25rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 0.125rem;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: none;
  --bs-offcanvas-title-line-height: 1.125;
  --bs-offcanvas-transition-duration: 0.3s;
}

@media (max-width: 479.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform var(--bs-offcanvas-transition-duration) ease-in-out;
  }
}
@media (max-width: 479.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 479.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 479.98px) {
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 479.98px) {
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 479.98px) {
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 479.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 479.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 480px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform var(--bs-offcanvas-transition-duration) ease-in-out;
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1023.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform var(--bs-offcanvas-transition-duration) ease-in-out;
  }
}
@media (max-width: 1023.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 1023.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1023.98px) {
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1023.98px) {
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1023.98px) {
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1023.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1023.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 1024px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1279.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform var(--bs-offcanvas-transition-duration) ease-in-out;
  }
}
@media (max-width: 1279.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1279.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1279.98px) {
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1279.98px) {
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1279.98px) {
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1279.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1279.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1280px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1439.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform var(--bs-offcanvas-transition-duration) ease-in-out;
  }
}
@media (max-width: 1439.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1439.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1439.98px) {
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1439.98px) {
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1439.98px) {
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1439.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1439.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1440px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: transform var(--bs-offcanvas-transition-duration) ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  --bs-offcanvas-backdrop-bg: #000;
  --bs-offcanvas-backdrop-opacity: 0.5;
  --bs-offcanvas-backdrop-zindex: 1040;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-offcanvas-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-offcanvas-backdrop-bg);
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: var(--bs-offcanvas-backdrop-opacity);
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  -webkit-mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0%;
    mask-position: -200% 0%;
  }
}
[id=top]:target {
  scroll-margin-top: 7.5rem;
}

.back-to-top {
  --bs-back-to-top-top: 100vh;
  --bs-back-to-top-right: 1.875rem;
  --bs-back-to-top-bottom: 1.875rem;
  --bs-back-to-top-zindex: 1035;
  --bs-back-to-top-link-offset-top: calc(100vh - 7.5rem);
  --bs-back-to-top-link-icon: var(--o-chevron-icon);
  --bs-back-to-top-link-icon-margin-left: 0.625rem;
  --bs-back-to-top-link-width: calc(0.5rem + 1px);
  --bs-back-to-top-link-height: calc(1rem - 1px);
  --bs-back-to-top-title-offset-right: calc(100% + 0.125rem);
  --bs-back-to-top-title-padding: calc(0.5rem - 1px) 1.125rem calc(0.5rem + 1px);
  --bs-back-to-top-title-color: #000;
  --bs-back-to-top-title-bg-color: #fff;
  --bs-back-to-top-title-active-decoration: underline;
  position: absolute;
  top: var(--bs-back-to-top-top);
  right: var(--bs-back-to-top-right);
  bottom: var(--bs-back-to-top-bottom);
  z-index: var(--bs-back-to-top-zindex);
  pointer-events: none;
}
@media (min-width: 1280px) {
  .back-to-top {
    --bs-back-to-top-right: 3.75rem;
    --bs-back-to-top-bottom: 3.75rem;
    --bs-back-to-top-link-offset-top: calc(100vh - 6.25rem);
  }
}

.back-to-top-link {
  position: -webkit-sticky;
  position: sticky;
  top: var(--bs-back-to-top-link-offset-top);
  pointer-events: all;
  min-width: 1.25rem;
  min-height: 1.25rem;
}
.back-to-top-link::after {
  display: block;
  min-width: inherit;
  min-height: inherit;
  content: "";
  background-color: currentcolor;
  -webkit-mask: var(--bs-back-to-top-link-icon) no-repeat calc(50% - 1px)/var(--bs-back-to-top-link-width) var(--bs-back-to-top-link-height);
  mask: var(--bs-back-to-top-link-icon) no-repeat calc(50% - 1px)/var(--bs-back-to-top-link-width) var(--bs-back-to-top-link-height);
}

.back-to-top-link::after {
  transform: rotate(0.25turn) /* rtl:ignore */;
}
.back-to-top-link:not([title]):not([data-bs-label])::after {
  margin-left: var(--bs-back-to-top-link-icon-margin-left);
}
@media (min-width: 1280px) {
  .back-to-top-link[data-bs-label]::before {
    position: absolute;
    right: var(--bs-back-to-top-title-offset-right);
    z-index: -1;
    padding: var(--bs-back-to-top-title-padding);
    color: var(--bs-back-to-top-title-color);
    white-space: nowrap;
    content: attr(data-bs-label);
    background-color: var(--bs-back-to-top-title-bg-color);
  }
  .back-to-top-link[data-bs-label]:hover::before {
    -webkit-text-decoration: var(--bs-back-to-top-title-active-decoration);
    text-decoration: var(--bs-back-to-top-title-active-decoration);
  }
}

.footer > * {
  --bs-footer-gap: 0;
  --bs-footer-padding-top: 0;
  --bs-footer-padding-bottom: 0;
  --bs-footer-title-margin-bottom: 0;
  --bs-footer-title-font-size: 1rem;
  --bs-footer-title-font-weight: 700;
  --bs-footer-title-line-height: 1.125;
  --bs-footer-title-letter-spacing: -0.00625rem;
  --bs-navbar-nav-font-size: 0.875rem;
  --bs-navbar-nav-line-height: 1.1428571429;
  --bs-navbar-nav-letter-spacing: -0.00625rem;
  padding-top: var(--bs-footer-padding-top);
  padding-bottom: var(--bs-footer-padding-bottom);
}
.footer > * .navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0;
  --bs-nav-link-font-weight: 700;
  flex-wrap: wrap;
  gap: var(--bs-footer-gap);
}
.footer > * .navbar-nav .nav-link {
  outline-offset: 2px;
}
.footer > * .navbar-nav .nav-link.active::before {
  content: unset;
}
.footer > * .footer-heading {
  margin-bottom: var(--bs-footer-title-margin-bottom);
  font-size: var(--bs-footer-title-font-size);
  font-weight: var(--bs-footer-title-font-weight);
  line-height: var(--bs-footer-title-line-height);
  letter-spacing: var(--bs-footer-title-letter-spacing);
  white-space: nowrap;
}
.footer .footer-title-content {
  --bs-footer-padding-top: 1.5625rem;
  --bs-footer-padding-bottom: 1.8125rem;
  --bs-footer-title-margin-bottom: 1.0625rem;
}
.footer .footer-social {
  --bs-footer-padding-top: 1.0625rem;
  --bs-footer-padding-bottom: 1.8125rem;
  --bs-footer-title-margin-bottom: 1.0625rem;
  --bs-footer-title-font-size: 0.875rem;
  --bs-footer-title-line-height: 1.1428571429;
  display: flex;
  flex-direction: column;
}
.footer .footer-nav {
  --bs-footer-gap: 2.125rem;
  --bs-navbar-nav-padding-top: 1.0625rem;
  --bs-navbar-nav-padding-x: 0;
  --bs-navbar-nav-padding-bottom: 1.625rem;
}
.footer .footer-nav .accordion {
  --bs-accordion-btn-font-size: 0.875rem;
  --bs-accordion-btn-line-height: 1.8125rem;
  --bs-accordion-btn-letter-spacing: -0.00625rem;
}
.footer .footer-nav .footer-column:first-of-type .accordion-header:first-of-type,
.footer .footer-nav .accordion .accordion-item {
  border: 0;
}
.footer .footer-nav .accordion-button::after {
  margin-right: 0;
}
.footer .footer-service {
  --bs-footer-padding-top: 1.1875rem;
  --bs-footer-padding-bottom: 1.25rem;
}
.footer .footer-service .nav-link > span {
  padding-top: 0.125rem;
}
.footer .footer-terms {
  --bs-footer-padding-top: 1.0625rem;
  --bs-footer-padding-bottom: 1.6875rem;
  --bs-footer-gap: 2.125rem;
}
@media (min-width: 768px) {
  .footer .navbar-nav {
    flex-direction: row;
  }
  .footer .footer-title-content {
    --bs-footer-padding-bottom: 2.4375rem;
  }
  .footer .footer-social {
    --bs-footer-padding-top: 1.875rem;
    --bs-footer-title-margin-bottom: 0.125rem;
    --bs-footer-title-font-size: 1rem;
    --bs-footer-title-line-height: 1.125;
    flex-direction: row;
    align-items: center;
  }
  .footer .footer-nav {
    --bs-footer-gap: 0.9375rem;
    --bs-footer-padding-top: 1.9375rem;
    --bs-footer-padding-bottom: 2.1875rem;
    --bs-footer-title-margin-bottom: 1.3125rem;
    --bs-navbar-nav-padding-top: 0.0625rem;
    --bs-navbar-nav-padding-bottom: null;
    --bs-navbar-nav-font-size: 1rem;
    --bs-navbar-nav-line-height: 1.125;
  }
  .footer .footer-nav .accordion {
    --bs-accordion-border-width: 0;
  }
  .footer .footer-nav .collapse {
    display: flex;
    padding-left: 0;
  }
  .footer .footer-nav .accordion-button::after {
    content: unset;
  }
  .footer .footer-nav .navbar-nav {
    flex-direction: column;
  }
  .footer .footer-service {
    --bs-footer-padding-top: 1.5625rem;
    --bs-footer-padding-bottom: 1.5rem;
  }
  .footer .footer-terms {
    --bs-footer-padding-top: 1.375rem;
    --bs-footer-padding-bottom: 1.375rem;
  }
}
@media (min-width: 1024px) {
  .footer .footer-nav {
    --bs-footer-padding-bottom: 2.5rem;
  }
}
@media (max-width: 767.98px) {
  .footer .footer-nav {
    max-width: unset;
    padding: 0;
  }
  .footer .footer-nav .row {
    --bs-gutter-x: 0;
  }
}

.supra {
  --bs-navbar-padding-y: 0px; /* stylelint-disable-line length-zero-no-unit */
  --bs-navbar-nav-font-size: 0.875rem;
  --bs-navbar-nav-line-height: 1.1428571429;
  --bs-navbar-nav-link-padding-y: 0.75rem;
  --bs-navbar-nav-link-padding-x: 0.46875rem;
  --bs-navbar-nav-icon-padding-y: 0.3125rem;
  --bs-navbar-nav-icon-padding-x: 0.9375rem;
  --bs-navbar-nav-icon-size: 1.5625rem;
  display: none;
}
.supra .navbar-nav .nav-link {
  padding-top: var(--bs-navbar-nav-link-padding-y);
}
.supra .navbar-nav .nav-link.nav-icon {
  padding-top: calc(var(--bs-navbar-nav-icon-padding-y) + 0.3125rem);
}

@media (min-width: 768px) {
  .header-minimized {
    --bs-navbar-padding-y: 0px; /* stylelint-disable-line length-zero-no-unit */
    --bs-navbar-brand-margin-y: 0.9375rem;
    --bs-navbar-brand-logo-size: 1.875rem;
    --bs-navbar-brand-font-size: 1.3125rem;
    --bs-navbar-brand-letter-spacing: -0.03125rem;
    --bs-navbar-nav-icon-padding-y: 0.9375rem;
    --bs-navbar-toggler-padding-y: 0.9375rem;
  }
  .header-minimized .two-lined {
    --bs-navbar-brand-font-size: 1.0625rem;
    --bs-navbar-brand-letter-spacing: -0.025rem;
  }
}

.stepped-process {
  --bs-stepped-process-font-size: 0.875rem;
  --bs-stepped-process-font-weight: 700;
  --bs-stepped-process-counter: step;
  --bs-stepped-process-item-padding-y: 7px;
  --bs-stepped-process-item-padding-x: 0;
  --bs-stepped-process-item-margin-end: 0.125rem;
  --bs-stepped-process-item-bg: #000;
  --bs-stepped-process-item-active-bg: #ff7900;
  --bs-stepped-process-item-next-bg: #ddd;
  --bs-stepped-process-item-drop-shadow: none;
  --bs-stepped-process-item-arrow-width: 0;
  --bs-stepped-process-item-arrow-shape: polygon(0% 0%, 1px 0%, calc(100% - 0.125rem) 50%, 1px 100%, 0% 100%) /* rtl: polygon(100% 0%, calc(100% - 1px) 0%, 0.125rem 50%, calc(100% - 1px) 100%, 100% 100%) */;
  --bs-stepped-process-link-width: 1.25ch;
  --bs-stepped-process-link-line-height: 1.1428571429;
  --bs-stepped-process-link-color: #fff;
  --bs-stepped-process-link-active-color: #000;
  --bs-stepped-process-link-next-color: #000;
  --bs-stepped-process-link-marker: counter(var(--bs-stepped-process-counter)) " ";
  --bs-stepped-process-link-text-decoration: underline;
}
@media (min-width: 480px) {
  .stepped-process {
    --bs-stepped-process-item-padding-x: 0;
    --bs-stepped-process-item-margin-end: 0;
    --bs-stepped-process-item-drop-shadow: drop-shadow(0.1875rem 0 0 #fff) /* rtl: drop-shadow(-0.1875rem 0 0 #fff) */;
    --bs-stepped-process-item-arrow-width: 0.8125rem;
  }
}
@media (min-width: 1280px) {
  .stepped-process {
    --bs-stepped-process-link-width: none;
    --bs-stepped-process-link-marker: counter(var(--bs-stepped-process-counter)) ". ";
  }
}
.stepped-process ol {
  display: flex;
  padding: 0;
  margin: 0;
  overflow: hidden;
  font-size: var(--bs-stepped-process-font-size);
  font-weight: var(--bs-stepped-process-font-weight);
  text-align: center;
  list-style: none;
  counter-reset: var(--bs-stepped-process-counter);
}

.stepped-process-item {
  position: relative;
  display: flex;
  flex: 1;
  padding: var(--bs-stepped-process-item-padding-y) var(--bs-stepped-process-item-padding-x);
  counter-increment: var(--bs-stepped-process-counter);
  background-color: var(--bs-stepped-process-item-bg);
  filter: var(--bs-stepped-process-item-drop-shadow);
}
.stepped-process-item:not(:last-child) {
  margin-right: var(--bs-stepped-process-item-margin-end);
}
.stepped-process-item:not(:last-child)::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: calc(100% - 1px);
  z-index: -1;
  width: var(--bs-stepped-process-item-arrow-width);
  -webkit-clip-path: var(--bs-stepped-process-item-arrow-shape);
  clip-path: var(--bs-stepped-process-item-arrow-shape);
  content: "";
  background-color: inherit;
}
.stepped-process-item.active {
  background-color: var(--bs-stepped-process-item-active-bg);
}
.active ~ .stepped-process-item {
  background-color: var(--bs-stepped-process-item-next-bg);
}
.stepped-process-item + .stepped-process-item {
  padding-left: var(--bs-stepped-process-item-arrow-width);
}

.stepped-process-link {
  flex: 1 0 var(--bs-stepped-process-link-width);
  max-width: var(--bs-stepped-process-link-width);
  margin: auto;
  overflow: hidden;
  line-height: var(--bs-stepped-process-link-line-height);
  color: var(--bs-stepped-process-link-color);
  text-decoration: none;
  white-space: nowrap;
  outline-color: var(--bs-focus-visible-inner-color);
  box-shadow: none;
}
.stepped-process-link::before {
  content: var(--bs-stepped-process-link-marker);
}
.stepped-process-link:hover {
  color: var(--bs-stepped-process-link-color);
}
.stepped-process-link[href]:hover {
  text-decoration: underline;
}
.active .stepped-process-link {
  color: var(--bs-stepped-process-link-active-color);
  outline-color: #000;
}
.active ~ .stepped-process-item .stepped-process-link {
  color: var(--bs-stepped-process-link-next-color);
  outline-color: var(--bs-focus-visible-outer-color);
}

.stepped-process-item:nth-child(1) {
  z-index: 4;
}

.stepped-process-item:nth-child(2) {
  z-index: 3;
}

.stepped-process-item:nth-child(3) {
  z-index: 2;
}

.stepped-process-item:nth-child(4) {
  z-index: 1;
}

@media (min-width: 480px) {
  .active .stepped-process-link {
    --bs-stepped-process-link-marker: counter(var(--bs-stepped-process-counter)) ". ";
    max-width: none;
  }
}
@media (min-width: 480px) and (max-width: 1279.98px) {
  .stepped-process-item.active {
    flex: 4;
  }
}
.stepped-process-dark {
  --bs-stepped-process-item-bg: #fff;
  --bs-stepped-process-item-active-bg: #ff7900;
  --bs-stepped-process-item-next-bg: #666;
  --bs-stepped-process-item-drop-shadow: drop-shadow(0.1875rem 0 0 #000) /* rtl: drop-shadow(-0.1875rem 0 0 #000) */;
  --bs-stepped-process-link-color: #000;
  --bs-stepped-process-link-active-color: #000;
  --bs-stepped-process-link-next-color: #fff;
}

.sticker {
  --bs-sticker-size: 11.25rem;
  --bs-sticker-font-weight: 700;
  --bs-sticker-background-color: #ff7900;
  --bs-sticker-content-max-width: 7.9549512885rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: var(--bs-sticker-size);
  height: var(--bs-sticker-size);
  font-weight: var(--bs-sticker-font-weight);
  text-align: center;
  word-wrap: break-word;
  background-color: var(--bs-sticker-background-color);
  border-radius: var(--bs-sticker-size);
}
.sticker > * {
  max-width: var(--bs-sticker-content-max-width);
}
.sticker.sticker-lg {
  --bs-sticker-size: 17.5rem;
  --bs-sticker-content-max-width: 12.374368671rem;
}
.sticker.sticker-sm {
  --bs-sticker-size: 8.75rem;
  --bs-sticker-content-max-width: 6.1871843355rem;
}

.title-bar {
  --bs-title-bar-image-ratio: 1.8em;
  --bs-title-bar-padding-y: 0.3333333em;
  --bs-title-bar-font-size: 1.875rem;
  --bs-title-bar-line-height: 1;
  --bs-title-bar-letter-spacing: -0.05rem;
  --bs-title-bar-border-width: 0.0625rem;
  --bs-title-bar-border-color: #ccc;
  border-bottom: var(--bs-title-bar-border-width) solid var(--bs-title-bar-border-color);
}
@media (min-width: 768px) {
  .title-bar {
    --bs-title-bar-font-size: 3.125rem;
    --bs-title-bar-letter-spacing: -0.1rem;
  }
}
@media (min-width: 1280px) {
  .title-bar {
    --bs-title-bar-font-size: 3.75rem;
    --bs-title-bar-letter-spacing: -0.125rem;
  }
}
.title-bar.bg-dark, .title-bar.bg-secondary {
  --bs-title-bar-border-color: #666;
}
.title-bar > [class*=container] {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.title-bar img,
.title-bar svg {
  width: auto;
  height: var(--bs-title-bar-image-ratio);
}
.title-bar .display-1, .title-bar {
  font-size: var(--bs-title-bar-font-size);
  line-height: var(--bs-title-bar-line-height);
  letter-spacing: var(--bs-title-bar-letter-spacing);
}
.title-bar h1, .title-bar .h1 {
  padding: calc(var(--bs-title-bar-padding-y) * 2 - var(--bs-title-bar-border-width)) 0 var(--bs-title-bar-padding-y);
  margin: 0;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #000 !important;
  background-color: RGBA(255, 121, 0, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(0, 0, 0, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #000 !important;
  background-color: RGBA(50, 200, 50, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(82, 126, 219, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 204, 0, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(205, 60, 20, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(204, 204, 204, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(0, 0, 0, var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: #f16e00 !important;
  background-color: #000 !important;
}
.link-primary:hover {
  color: #f48b33 !important;
}

.link-light {
  color: #ccc !important;
  background-color: #000 !important;
}
.link-light:hover {
  color: #d6d6d6 !important;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.ratio-9x16 {
  --bs-aspect-ratio: 177.7777777778%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 480px) {
  .sticky-sm-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1024px) {
  .sticky-lg-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1280px) {
  .sticky-xl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1440px) {
  .sticky-xxl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden, .valid-feedback,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 2px;
  min-height: 1em;
  background-color: currentcolor;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(204, 204, 204, var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(102, 102, 102, var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-1 {
  --bs-border-width: 0.0625rem;
}

.border-2 {
  --bs-border-width: 0.125rem;
}

.border-3 {
  --bs-border-width: 0.1875rem;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.3125rem !important;
}

.m-2 {
  margin: 0.625rem !important;
}

.m-3 {
  margin: 1.25rem !important;
}

.m-4 {
  margin: 1.875rem !important;
}

.m-5 {
  margin: 3.75rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.3125rem !important;
  margin-left: 0.3125rem !important;
}

.mx-2 {
  margin-right: 0.625rem !important;
  margin-left: 0.625rem !important;
}

.mx-3 {
  margin-right: 1.25rem !important;
  margin-left: 1.25rem !important;
}

.mx-4 {
  margin-right: 1.875rem !important;
  margin-left: 1.875rem !important;
}

.mx-5 {
  margin-right: 3.75rem !important;
  margin-left: 3.75rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.3125rem !important;
  margin-bottom: 0.3125rem !important;
}

.my-2 {
  margin-top: 0.625rem !important;
  margin-bottom: 0.625rem !important;
}

.my-3 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.my-4 {
  margin-top: 1.875rem !important;
  margin-bottom: 1.875rem !important;
}

.my-5 {
  margin-top: 3.75rem !important;
  margin-bottom: 3.75rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.3125rem !important;
}

.mt-2 {
  margin-top: 0.625rem !important;
}

.mt-3 {
  margin-top: 1.25rem !important;
}

.mt-4 {
  margin-top: 1.875rem !important;
}

.mt-5 {
  margin-top: 3.75rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.3125rem !important;
}

.me-2 {
  margin-right: 0.625rem !important;
}

.me-3 {
  margin-right: 1.25rem !important;
}

.me-4 {
  margin-right: 1.875rem !important;
}

.me-5 {
  margin-right: 3.75rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.3125rem !important;
}

.mb-2 {
  margin-bottom: 0.625rem !important;
}

.mb-3 {
  margin-bottom: 1.25rem !important;
}

.mb-4 {
  margin-bottom: 1.875rem !important;
}

.mb-5 {
  margin-bottom: 3.75rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.3125rem !important;
}

.ms-2 {
  margin-left: 0.625rem !important;
}

.ms-3 {
  margin-left: 1.25rem !important;
}

.ms-4 {
  margin-left: 1.875rem !important;
}

.ms-5 {
  margin-left: 3.75rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.3125rem !important;
}

.p-2 {
  padding: 0.625rem !important;
}

.p-3 {
  padding: 1.25rem !important;
}

.p-4 {
  padding: 1.875rem !important;
}

.p-5 {
  padding: 3.75rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.3125rem !important;
  padding-left: 0.3125rem !important;
}

.px-2 {
  padding-right: 0.625rem !important;
  padding-left: 0.625rem !important;
}

.px-3 {
  padding-right: 1.25rem !important;
  padding-left: 1.25rem !important;
}

.px-4 {
  padding-right: 1.875rem !important;
  padding-left: 1.875rem !important;
}

.px-5 {
  padding-right: 3.75rem !important;
  padding-left: 3.75rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.3125rem !important;
  padding-bottom: 0.3125rem !important;
}

.py-2 {
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important;
}

.py-3 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.py-4 {
  padding-top: 1.875rem !important;
  padding-bottom: 1.875rem !important;
}

.py-5 {
  padding-top: 3.75rem !important;
  padding-bottom: 3.75rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.3125rem !important;
}

.pt-2 {
  padding-top: 0.625rem !important;
}

.pt-3 {
  padding-top: 1.25rem !important;
}

.pt-4 {
  padding-top: 1.875rem !important;
}

.pt-5 {
  padding-top: 3.75rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.3125rem !important;
}

.pe-2 {
  padding-right: 0.625rem !important;
}

.pe-3 {
  padding-right: 1.25rem !important;
}

.pe-4 {
  padding-right: 1.875rem !important;
}

.pe-5 {
  padding-right: 3.75rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.3125rem !important;
}

.pb-2 {
  padding-bottom: 0.625rem !important;
}

.pb-3 {
  padding-bottom: 1.25rem !important;
}

.pb-4 {
  padding-bottom: 1.875rem !important;
}

.pb-5 {
  padding-bottom: 3.75rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.3125rem !important;
}

.ps-2 {
  padding-left: 0.625rem !important;
}

.ps-3 {
  padding-left: 1.25rem !important;
}

.ps-4 {
  padding-left: 1.875rem !important;
}

.ps-5 {
  padding-left: 3.75rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.3125rem !important;
}

.gap-2 {
  gap: 0.625rem !important;
}

.gap-3 {
  gap: 1.25rem !important;
}

.gap-4 {
  gap: 1.875rem !important;
}

.gap-5 {
  gap: 3.75rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: 2.125rem !important;
}

.fs-2 {
  font-size: 1.875rem !important;
}

.fs-3 {
  font-size: 1.5rem !important;
}

.fs-4 {
  font-size: 1.25rem !important;
}

.fs-5 {
  font-size: 1.125rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.1428571429 !important;
}

.lh-base {
  line-height: 1.125 !important;
}

.lh-lg {
  line-height: 1.875 !important;
}

.ll-sm {
  max-width: 40ch !important;
}

.ll-md {
  max-width: 80ch !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-text-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
  background-color: #000;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
  background-color: #000;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
  background-color: #fff;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #666 !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
  background-color: #fff;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
  background-color: #000;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
  color: #000;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
  color: #fff;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
  color: #000;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
  color: #000;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
  color: #000;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
  color: #fff;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
  color: #000;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
  color: #fff;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
  color: #fff;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: #fff !important;
  color: #000;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: #fff !important;
  color: #000;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-supporting-green {
  --bs-bg-opacity: 1;
  background-color: #50be87 !important;
  color: #000;
}

.bg-supporting-blue {
  --bs-bg-opacity: 1;
  background-color: #4bb4e6 !important;
  color: #000;
}

.bg-supporting-yellow {
  --bs-bg-opacity: 1;
  background-color: #ffd200 !important;
  color: #000;
}

.bg-supporting-pink {
  --bs-bg-opacity: 1;
  background-color: #ffb4e6 !important;
  color: #000;
}

.bg-supporting-purple {
  --bs-bg-opacity: 1;
  background-color: #a885d8 !important;
  color: #000;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 480px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.3125rem !important;
  }
  .m-sm-2 {
    margin: 0.625rem !important;
  }
  .m-sm-3 {
    margin: 1.25rem !important;
  }
  .m-sm-4 {
    margin: 1.875rem !important;
  }
  .m-sm-5 {
    margin: 3.75rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-sm-3 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-sm-5 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }
  .my-sm-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-sm-3 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-sm-4 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-sm-5 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.3125rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.625rem !important;
  }
  .mt-sm-3 {
    margin-top: 1.25rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.875rem !important;
  }
  .mt-sm-5 {
    margin-top: 3.75rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.3125rem !important;
  }
  .me-sm-2 {
    margin-right: 0.625rem !important;
  }
  .me-sm-3 {
    margin-right: 1.25rem !important;
  }
  .me-sm-4 {
    margin-right: 1.875rem !important;
  }
  .me-sm-5 {
    margin-right: 3.75rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.3125rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.625rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1.25rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.875rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3.75rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.3125rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.625rem !important;
  }
  .ms-sm-3 {
    margin-left: 1.25rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.875rem !important;
  }
  .ms-sm-5 {
    margin-left: 3.75rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.3125rem !important;
  }
  .p-sm-2 {
    padding: 0.625rem !important;
  }
  .p-sm-3 {
    padding: 1.25rem !important;
  }
  .p-sm-4 {
    padding: 1.875rem !important;
  }
  .p-sm-5 {
    padding: 3.75rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important;
  }
  .px-sm-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-sm-3 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-sm-4 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-sm-5 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }
  .py-sm-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-sm-3 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-sm-4 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-sm-5 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.3125rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.625rem !important;
  }
  .pt-sm-3 {
    padding-top: 1.25rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.875rem !important;
  }
  .pt-sm-5 {
    padding-top: 3.75rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.3125rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.625rem !important;
  }
  .pe-sm-3 {
    padding-right: 1.25rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.875rem !important;
  }
  .pe-sm-5 {
    padding-right: 3.75rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.625rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1.25rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.875rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3.75rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.3125rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.625rem !important;
  }
  .ps-sm-3 {
    padding-left: 1.25rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.875rem !important;
  }
  .ps-sm-5 {
    padding-left: 3.75rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.3125rem !important;
  }
  .gap-sm-2 {
    gap: 0.625rem !important;
  }
  .gap-sm-3 {
    gap: 1.25rem !important;
  }
  .gap-sm-4 {
    gap: 1.875rem !important;
  }
  .gap-sm-5 {
    gap: 3.75rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.3125rem !important;
  }
  .m-md-2 {
    margin: 0.625rem !important;
  }
  .m-md-3 {
    margin: 1.25rem !important;
  }
  .m-md-4 {
    margin: 1.875rem !important;
  }
  .m-md-5 {
    margin: 3.75rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important;
  }
  .mx-md-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-md-3 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-md-4 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-md-5 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }
  .my-md-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-md-3 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-md-4 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-md-5 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.3125rem !important;
  }
  .mt-md-2 {
    margin-top: 0.625rem !important;
  }
  .mt-md-3 {
    margin-top: 1.25rem !important;
  }
  .mt-md-4 {
    margin-top: 1.875rem !important;
  }
  .mt-md-5 {
    margin-top: 3.75rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.3125rem !important;
  }
  .me-md-2 {
    margin-right: 0.625rem !important;
  }
  .me-md-3 {
    margin-right: 1.25rem !important;
  }
  .me-md-4 {
    margin-right: 1.875rem !important;
  }
  .me-md-5 {
    margin-right: 3.75rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.3125rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.625rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1.25rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.875rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3.75rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.3125rem !important;
  }
  .ms-md-2 {
    margin-left: 0.625rem !important;
  }
  .ms-md-3 {
    margin-left: 1.25rem !important;
  }
  .ms-md-4 {
    margin-left: 1.875rem !important;
  }
  .ms-md-5 {
    margin-left: 3.75rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.3125rem !important;
  }
  .p-md-2 {
    padding: 0.625rem !important;
  }
  .p-md-3 {
    padding: 1.25rem !important;
  }
  .p-md-4 {
    padding: 1.875rem !important;
  }
  .p-md-5 {
    padding: 3.75rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important;
  }
  .px-md-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-md-3 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-md-4 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-md-5 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }
  .py-md-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-md-3 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-md-4 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-md-5 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.3125rem !important;
  }
  .pt-md-2 {
    padding-top: 0.625rem !important;
  }
  .pt-md-3 {
    padding-top: 1.25rem !important;
  }
  .pt-md-4 {
    padding-top: 1.875rem !important;
  }
  .pt-md-5 {
    padding-top: 3.75rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.3125rem !important;
  }
  .pe-md-2 {
    padding-right: 0.625rem !important;
  }
  .pe-md-3 {
    padding-right: 1.25rem !important;
  }
  .pe-md-4 {
    padding-right: 1.875rem !important;
  }
  .pe-md-5 {
    padding-right: 3.75rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.625rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1.25rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.875rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3.75rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.3125rem !important;
  }
  .ps-md-2 {
    padding-left: 0.625rem !important;
  }
  .ps-md-3 {
    padding-left: 1.25rem !important;
  }
  .ps-md-4 {
    padding-left: 1.875rem !important;
  }
  .ps-md-5 {
    padding-left: 3.75rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.3125rem !important;
  }
  .gap-md-2 {
    gap: 0.625rem !important;
  }
  .gap-md-3 {
    gap: 1.25rem !important;
  }
  .gap-md-4 {
    gap: 1.875rem !important;
  }
  .gap-md-5 {
    gap: 3.75rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 1024px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.3125rem !important;
  }
  .m-lg-2 {
    margin: 0.625rem !important;
  }
  .m-lg-3 {
    margin: 1.25rem !important;
  }
  .m-lg-4 {
    margin: 1.875rem !important;
  }
  .m-lg-5 {
    margin: 3.75rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-lg-3 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-lg-5 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }
  .my-lg-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-lg-3 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-lg-4 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-lg-5 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.3125rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.625rem !important;
  }
  .mt-lg-3 {
    margin-top: 1.25rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.875rem !important;
  }
  .mt-lg-5 {
    margin-top: 3.75rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.3125rem !important;
  }
  .me-lg-2 {
    margin-right: 0.625rem !important;
  }
  .me-lg-3 {
    margin-right: 1.25rem !important;
  }
  .me-lg-4 {
    margin-right: 1.875rem !important;
  }
  .me-lg-5 {
    margin-right: 3.75rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.3125rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.625rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1.25rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.875rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3.75rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.3125rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.625rem !important;
  }
  .ms-lg-3 {
    margin-left: 1.25rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.875rem !important;
  }
  .ms-lg-5 {
    margin-left: 3.75rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.3125rem !important;
  }
  .p-lg-2 {
    padding: 0.625rem !important;
  }
  .p-lg-3 {
    padding: 1.25rem !important;
  }
  .p-lg-4 {
    padding: 1.875rem !important;
  }
  .p-lg-5 {
    padding: 3.75rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important;
  }
  .px-lg-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-lg-3 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-lg-4 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-lg-5 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }
  .py-lg-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-lg-3 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-lg-4 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-lg-5 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.3125rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.625rem !important;
  }
  .pt-lg-3 {
    padding-top: 1.25rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.875rem !important;
  }
  .pt-lg-5 {
    padding-top: 3.75rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.3125rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.625rem !important;
  }
  .pe-lg-3 {
    padding-right: 1.25rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.875rem !important;
  }
  .pe-lg-5 {
    padding-right: 3.75rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.625rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1.25rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.875rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3.75rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.3125rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.625rem !important;
  }
  .ps-lg-3 {
    padding-left: 1.25rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.875rem !important;
  }
  .ps-lg-5 {
    padding-left: 3.75rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.3125rem !important;
  }
  .gap-lg-2 {
    gap: 0.625rem !important;
  }
  .gap-lg-3 {
    gap: 1.25rem !important;
  }
  .gap-lg-4 {
    gap: 1.875rem !important;
  }
  .gap-lg-5 {
    gap: 3.75rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1280px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.3125rem !important;
  }
  .m-xl-2 {
    margin: 0.625rem !important;
  }
  .m-xl-3 {
    margin: 1.25rem !important;
  }
  .m-xl-4 {
    margin: 1.875rem !important;
  }
  .m-xl-5 {
    margin: 3.75rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-xl-3 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-xl-5 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }
  .my-xl-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-xl-3 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-xl-4 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-xl-5 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.3125rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.625rem !important;
  }
  .mt-xl-3 {
    margin-top: 1.25rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.875rem !important;
  }
  .mt-xl-5 {
    margin-top: 3.75rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.3125rem !important;
  }
  .me-xl-2 {
    margin-right: 0.625rem !important;
  }
  .me-xl-3 {
    margin-right: 1.25rem !important;
  }
  .me-xl-4 {
    margin-right: 1.875rem !important;
  }
  .me-xl-5 {
    margin-right: 3.75rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.3125rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.625rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1.25rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.875rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3.75rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.3125rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.625rem !important;
  }
  .ms-xl-3 {
    margin-left: 1.25rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.875rem !important;
  }
  .ms-xl-5 {
    margin-left: 3.75rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.3125rem !important;
  }
  .p-xl-2 {
    padding: 0.625rem !important;
  }
  .p-xl-3 {
    padding: 1.25rem !important;
  }
  .p-xl-4 {
    padding: 1.875rem !important;
  }
  .p-xl-5 {
    padding: 3.75rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important;
  }
  .px-xl-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-xl-3 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-xl-4 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-xl-5 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }
  .py-xl-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-xl-3 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-xl-4 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-xl-5 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.3125rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.625rem !important;
  }
  .pt-xl-3 {
    padding-top: 1.25rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.875rem !important;
  }
  .pt-xl-5 {
    padding-top: 3.75rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.3125rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.625rem !important;
  }
  .pe-xl-3 {
    padding-right: 1.25rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.875rem !important;
  }
  .pe-xl-5 {
    padding-right: 3.75rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.625rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1.25rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.875rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3.75rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.3125rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.625rem !important;
  }
  .ps-xl-3 {
    padding-left: 1.25rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.875rem !important;
  }
  .ps-xl-5 {
    padding-left: 3.75rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.3125rem !important;
  }
  .gap-xl-2 {
    gap: 0.625rem !important;
  }
  .gap-xl-3 {
    gap: 1.25rem !important;
  }
  .gap-xl-4 {
    gap: 1.875rem !important;
  }
  .gap-xl-5 {
    gap: 3.75rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1440px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.3125rem !important;
  }
  .m-xxl-2 {
    margin: 0.625rem !important;
  }
  .m-xxl-3 {
    margin: 1.25rem !important;
  }
  .m-xxl-4 {
    margin: 1.875rem !important;
  }
  .m-xxl-5 {
    margin: 3.75rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-xxl-3 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-xxl-5 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.3125rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.625rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1.25rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.875rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3.75rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.3125rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.625rem !important;
  }
  .me-xxl-3 {
    margin-right: 1.25rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.875rem !important;
  }
  .me-xxl-5 {
    margin-right: 3.75rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.3125rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.625rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1.25rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.875rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3.75rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.3125rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.625rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1.25rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.875rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3.75rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.3125rem !important;
  }
  .p-xxl-2 {
    padding: 0.625rem !important;
  }
  .p-xxl-3 {
    padding: 1.25rem !important;
  }
  .p-xxl-4 {
    padding: 1.875rem !important;
  }
  .p-xxl-5 {
    padding: 3.75rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-xxl-3 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-xxl-5 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-xxl-3 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-xxl-5 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.3125rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.625rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1.25rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.875rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3.75rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.3125rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.625rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1.25rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.875rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3.75rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.625rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1.25rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.875rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3.75rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.3125rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.625rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1.25rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.875rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3.75rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.3125rem !important;
  }
  .gap-xxl-2 {
    gap: 0.625rem !important;
  }
  .gap-xxl-3 {
    gap: 1.25rem !important;
  }
  .gap-xxl-4 {
    gap: 1.875rem !important;
  }
  .gap-xxl-5 {
    gap: 3.75rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}

/*# sourceMappingURL=boosted.css.map */