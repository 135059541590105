:root {
  // Note: Custom variable values only support SassScript inside `#{}`.

  // Colors
  //
  // Generate palettes for full colors, grays, and theme colors.

  @each $color, $value in $colors {
    --#{$prefix}#{$color}: #{$value};
  }

  @each $color, $value in $grays {
    --#{$prefix}gray-#{$color}: #{$value};
  }

  @each $color, $value in $theme-colors {
    --#{$prefix}#{$color}: #{$value};
  }

  @each $color, $value in $theme-colors-rgb {
    --#{$prefix}#{$color}-rgb: #{$value};
  }

  --#{$prefix}primary-text-rgb: #{to-rgb($accessible-orange)}; // Boosted mod
  --#{$prefix}white-rgb: #{to-rgb($white)};
  --#{$prefix}black-rgb: #{to-rgb($black)};
  --#{$prefix}body-color-rgb: #{to-rgb($body-color)};
  --#{$prefix}body-bg-rgb: #{to-rgb($body-bg)};

  // Boosted mod
  @each $icon, $svg in $svg-as-custom-props {
    --#{$boosted-prefix}#{$icon}-icon: #{escape-svg($svg)};
  }
  // End mod

  // Fonts

  // Note: Use `inspect` for lists so that quoted items keep the quotes.
  // See https://github.com/sass/sass/issues/2383#issuecomment-336349172
  --#{$prefix}font-sans-serif: #{inspect($font-family-sans-serif)};
  --#{$prefix}font-monospace: #{inspect($font-family-monospace)};
  --#{$prefix}gradient: #{$gradient};

  // Root and body
  // scss-docs-start root-body-variables
  @if $font-size-root != null {
    --#{$prefix}root-font-size: #{$font-size-root};
  }
  --#{$prefix}body-font-family: #{$font-family-base};
  @include rfs($font-size-base, --#{$prefix}body-font-size);
  --#{$prefix}body-font-weight: #{$font-weight-base};
  --#{$prefix}body-line-height: #{$line-height-base};
  --#{$prefix}body-color: #{$body-color};
  @if $body-text-align != null {
    --#{$prefix}body-text-align: #{$body-text-align};
  }
  --#{$prefix}body-bg: #{$body-bg};
  // scss-docs-end root-body-variables

  // scss-docs-start root-border-var
  --#{$prefix}border-width: #{$border-width};
  --#{$prefix}border-style: #{$border-style};
  --#{$prefix}border-color: #{$border-color};
  --#{$prefix}border-color-translucent: #{$border-color-translucent};

  --#{$prefix}border-radius: #{$border-radius};
  --#{$prefix}border-radius-sm: #{$border-radius-sm};
  --#{$prefix}border-radius-lg: #{$border-radius-lg};
  --#{$prefix}border-radius-xl: #{$border-radius-xl};
  --#{$prefix}border-radius-2xl: #{$border-radius-2xl};
  --#{$prefix}border-radius-pill: #{$border-radius-pill};
  // scss-docs-end root-border-var

  --#{$prefix}link-color: #{$link-color};
  --#{$prefix}link-hover-color: #{$link-hover-color};

  --#{$prefix}focus-visible-inner-color: #{$focus-visible-inner-color}; // Boosted mod
  --#{$prefix}focus-visible-outer-color: #{$focus-visible-outer-color}; // Boosted mod

  --#{$prefix}code-color: #{$code-color};

  --#{$prefix}highlight-color: #{$mark-color}; // Boosted mod
  --#{$prefix}highlight-bg: #{$mark-bg};
}

// scss-docs-start root-dark-rule
// Boosted mod
[class*="bg-black"],
[class*="-dark"]:not(.border-dark):not(.text-dark):not(.btn-dark),
[class*="bg-secondary"] {
  --#{$prefix}primary-text-rgb: #{to-rgb($brand-orange)};
  --#{$prefix}link-color: #{$link-color-dark};
  --#{$prefix}link-hover-color: #{$link-hover-color-dark};
  --#{$prefix}focus-visible-inner-color: #{$focus-visible-inner-color-dark};
  --#{$prefix}focus-visible-outer-color: #{$focus-visible-outer-color-dark};
  --#{$boosted-prefix}caption-color: #{$table-caption-color-dark};
  --#{$prefix}code-color: #{$code-color-dark};
  --#{$prefix}highlight-color: #{$mark-color-dark};
  --#{$prefix}highlight-bg: #{$mark-bg-dark};
  --#{$boosted-prefix}kbd-color: #{$kbd-color-dark};
  --#{$boosted-prefix}kbd-bg: #{$kbd-bg-dark};
  --#{$boosted-prefix}pre-color: #{$pre-color-dark};

  // stylelint-disable-next-line scss/selector-no-redundant-nesting-selector
  [class*="bg-"]:not(&):not(.bg-transparent) {
    --#{$prefix}primary-text-rgb: #{to-rgb($accessible-orange)};
    --#{$prefix}link-color: #{$link-color};
    --#{$prefix}link-hover-color: #{$link-hover-color};
    --#{$prefix}focus-visible-inner-color: #{$focus-visible-inner-color};
    --#{$prefix}focus-visible-outer-color: #{$focus-visible-outer-color};
    --#{$boosted-prefix}caption-color: #{$table-caption-color};
    --#{$prefix}code-color: #{$code-color};
    --#{$prefix}highlight-color: #{$mark-color};
    --#{$prefix}highlight-bg: #{$mark-bg};
    --#{$boosted-prefix}kbd-color: #{$kbd-color};
    --#{$boosted-prefix}kbd-bg: #{$kbd-bg};
    --#{$boosted-prefix}pre-color: #{$pre-color};
  }
}
// End mod
// scss-docs-end root-dark-rule
