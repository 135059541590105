//
// Base styles
//

.alert {
  // scss-docs-start alert-css-vars
  --#{$prefix}alert-bg: transparent;
  --#{$prefix}alert-padding-x: #{$alert-padding-x};
  --#{$prefix}alert-padding-y: #{$alert-padding-y};
  --#{$prefix}alert-margin-bottom: #{$alert-margin-bottom};
  @include rfs($font-size-base, --#{$prefix}alert-font-size); // Boosted mod
  --#{$prefix}alert-line-height: #{$line-height-base}; // Boosted mod
  --#{$prefix}alert-color: #{$alert-color}; // Boosted mod
  --#{$prefix}alert-border-color: transparent;
  --#{$prefix}alert-border: #{$alert-border-width} solid var(--#{$prefix}alert-border-color);
  --#{$prefix}alert-border-radius: #{$alert-border-radius};
  --#{$prefix}alert-logo-size: #{$alert-logo-size}; // Boosted mod
  --#{$prefix}alert-icon-size: #{$alert-icon-size}; // Boosted mod
  --#{$prefix}alert-icon-margin-y: #{$alert-icon-margin-y}; // Boosted mod
  --#{$prefix}alert-link-font-weight: #{$alert-link-font-weight}; // Boosted mod
  --#{$prefix}alert-heading-font-weight: #{$alert-heading-font-weight}; // Boosted mod
  --#{$prefix}alert-dismissible-padding-right: #{$alert-dismissible-padding-r}; // Boosted mod
  --#{$prefix}alert-btn-close-offset: #{$alert-btn-close-offset}; // Boosted mod
  // scss-docs-end alert-css-vars

  position: relative;
  display: flex; // Boosted mod
  padding: var(--#{$prefix}alert-padding-y) var(--#{$prefix}alert-padding-x);
  margin-bottom: var(--#{$prefix}alert-margin-bottom);
  @include font-size(var(--#{$prefix}alert-font-size));
  line-height: var(--#{$prefix}alert-line-height);
  color: var(--#{$prefix}alert-color);
  background-color: var(--#{$prefix}alert-bg);
  border: var(--#{$prefix}alert-border);
  @include border-radius(var(--#{$prefix}alert-border-radius));
}

// Headings for larger alerts
.alert-heading {
  // Boosted mod: ensure headings look like paragraphs
  margin: 0;
  font-size: inherit;
  font-weight: var(--#{$prefix}alert-heading-font-weight);
  line-height: inherit;
  // End mod
  // Specified to prevent conflicts of changing $headings-color
  color: inherit;
}

// Provide class for links that match alerts
.alert-link {
  font-weight: var(--#{$prefix}alert-link-font-weight); // Boosted mod
}


// Dismissible alerts
//
// Expand the right padding and account for the close button's positioning.

.alert-dismissible {
  padding-right: var(--#{$prefix}alert-dismissible-padding-right); // Boosted mod

  // Adjust close link position
  .btn-close {
    position: absolute;
    top: var(--#{$prefix}alert-btn-close-offset); // Boosted mod
    right: var(--#{$prefix}alert-btn-close-offset); // Boosted mod
    z-index: $stretched-link-z-index + 1;
    // Boosted mod: no padding
  }
}


// scss-docs-start alert-modifiers
// Generate contextual modifier classes for colorizing the alert.

@each $state, $value in $alert-colors {
  $alert-background: transparent; // Boosted mod
  $alert-border: $alert-border-width solid $value; // Boosted mod
  // Boosted mod: no $alert-color change

  // Boosted mod: no contrast-ratio test
  .alert-#{$state} {
    @include alert-variant($alert-background, $alert-border, $alert-color, map-get($alert-icons, $state)); // Boosted mod
  }
}
// scss-docs-end alert-modifiers

//
// Boosted mod
//

//// Additional content
.alert * p {
  margin: 0;
}

.alert-heading ~ p {
  line-height: 1.5;
}

//// Icons
.alert-icon {
  flex-shrink: 0;
  width: var(--#{$prefix}alert-icon-size);
  margin: calc(-.5 * var(--#{$prefix}alert-icon-margin-y)) 0; // stylelint-disable-line function-disallowed-list

  + * {
    margin: var(--#{$prefix}alert-icon-margin-y) 0 0;
  }

  + p {
    font-weight: var(--#{$prefix}alert-heading-font-weight);
  }

  &::before {
    display: block;
    order: -1;
    height: var(--#{$prefix}alert-logo-size);
    content: "";
    background-repeat: no-repeat;

    /* rtl:raw:
    background-position: top right;
    */
    background-size: var(--#{$prefix}alert-logo-size);
  }
}

//// Small alerts
.alert-sm {
  // scss-docs-start alert-sm-css-vars
  --#{$prefix}alert-padding-x: 0;
  --#{$prefix}alert-padding-y: #{$alert-padding-sm};
  --#{$prefix}alert-margin-bottom: 0;
  @include rfs($font-size-sm, --#{$prefix}alert-font-size);
  --#{$prefix}alert-line-height: #{$line-height-sm};
  --#{$prefix}alert-border: 0;
  --#{$prefix}alert-logo-size: #{$alert-logo-size-sm};
  --#{$prefix}alert-icon-size: #{$alert-icon-size-sm};
  --#{$prefix}alert-btn-close-offset: #{$alert-btn-close-offset-sm};
  // scss-docs-end alert-sm-css-vars
  --#{$boosted-prefix}icon-spacing: #{$btn-close-padding-sm};

  .alert-icon {
    margin: var(--#{$prefix}alert-icon-margin-y) 0;
  }
}
// End mod
