@mixin bsBanner($file) {
  /*!
   * Boosted #{$file} v5.2.3 (https://boosted.orange.com/)
   * Copyright 2014-2022 The Boosted Authors
   * Copyright 2014-2022 Orange SA
   * Licensed under MIT (https://github.com/Orange-OpenSource/Orange-Boosted-Bootstrap/blob/main/LICENSE)
   * This a fork of Bootstrap: Initial license below
   * Bootstrap #{$file} v5.2.3 (https://getbootstrap.com/)
   * Copyright 2011-2022 The Bootstrap Authors
   * Copyright 2011-2022 Twitter, Inc.
   * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
   */
}
