// List Groups

// scss-docs-start list-group-mixin
@mixin list-group-item-variant($state, $background, $color) {
  .list-group-item-#{$state} {
    color: $color;
    background-color: $background;

    &.list-group-item-action {
      // Boosted mod: no &:focus
      &:hover {
        color: $color;
        background-color: if($color == $white, shade-color($background, 10%), tint-color($background, 10%)); // Boosted mod
      }

      // Boosted mod: no variant for active state
    }
  }
}
// scss-docs-end list-group-mixin
