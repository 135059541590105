.quantity-selector {
  width: $quantity-selector-width;

  .form-control {
    max-width: $quantity-selector-input-max-width;
    text-align: center;
    @include transition(none);
    appearance: textfield;

    &:not(:focus) {
      border-right: none;
      border-left: none;
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      margin: 0;
      appearance: none;
    }

    &:disabled {
      color: $gray-500;
      background-color: $white;
    }
  }

  button {
    border: $border-width solid $gray-500;

    &:first-of-type {
      @include button-icon($quantity-selector-icon-remove, $size: $quantity-selector-icon-width $quantity-selector-icon-remove-height, $pseudo: "after");
      order: -1;
      padding-right: $quantity-selector-btn-padding-x;
      border-right: none;

      &.btn-sm { // stylelint-disable-line selector-no-qualifying-type
        @include button-icon($quantity-selector-icon-remove-sm, $width: 1rem, $height: 1rem, $size: $quantity-selector-icon-sm-width $quantity-selector-icon-sm-remove-height, $pseudo: "after");
        padding-right: $quantity-selector-btn-padding-x-sm;
      }
    }

    &:last-of-type {
      @include button-icon($quantity-selector-icon-add, $size: $quantity-selector-icon-width $quantity-selector-icon-add-height, $pseudo: "after");
      padding-left: $quantity-selector-btn-padding-x;
      border-left: none;

      &.btn-sm { // stylelint-disable-line selector-no-qualifying-type
        @include button-icon($quantity-selector-icon-add-sm, $width: 1rem, $height: 1rem, $size: $quantity-selector-icon-sm-width $quantity-selector-icon-sm-add-height, $pseudo: "after");
        padding-left: $quantity-selector-btn-padding-x-sm;
      }
    }
  }
}

.quantity-selector-sm {
  width: $quantity-selector-sm-width;

  .form-control {
    max-width: $quantity-selector-input-sm-max-width;
    padding: subtract($input-padding-y-sm, 1px) 0 $input-padding-y-sm;
    font-size: $font-size-sm;
  }
}
